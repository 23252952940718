import {EventEmitter, Injectable} from '@angular/core'
import {Home} from '../models/home'
import {Device} from '../models/device'
import {AngularFirestore} from '@angular/fire/firestore'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable, Subscription} from 'rxjs'
import {Season} from '../models/season'
import {Playlist} from '../models/playlist'
import moment from 'moment'

@Injectable({
    providedIn:'root'
})
export class HomeSession {
    devicesEvent = new EventEmitter<string>()

    user:any = null
    home:Home = null
    devices:Device[] = []
    selectedDevice:Device = null
    selectedSeason:Season = null
    selectedTrigger = null
    subscription:Subscription = null
    favoritesSubscrition:Subscription = null
    seasonDates:any = {}
    storing = false
    selectedTimer:string = ''
    firstRoute = ''
    selectedAP:string = ''
    _seasonsCollection:Observable<any> = null
    _seasons:any[] = []
    _playlists:Observable<any>
    favorites:Playlist = null
    private _offSeasonPlaylists:Observable<any>
    isDev:boolean = false
    onMobile:boolean = false


    constructor(private fs:AngularFirestore,
                private http:HttpClient) {
    }

    reset() {
        if (this.subscription) {
            this.subscription.unsubscribe()
            this.subscription = null
        }
        this.devices = []
        this.home = null
    }

    init() {
        this.devices = []
        this.favorites = new Playlist()
        this.getDevicesInfo(this.user)
        this.getFavorites(this.user)
    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async storeDevices() {
        if (this.storing)
            await this.sleep(3)

        let promises:Promise<any>[] = []
        let first = this.devices[0]
        for (let device of this.devices) {
            if (first != device) {
                device.triggers = []
                device.mode = first._AUTO_MODE
                device.selectedSeason = first.selectedSeason
                device.seasons = first.seasons
                device.seasonDates = first.seasonDates
            }

            promises.push(this.fs.doc('lights/' + device.bleId).set(device.getDict()))
        }

        try {
            await Promise.all(promises)
        } catch (e) {
            await this.sleep(1)
        }

        this.storing = false
    }

    async updateDevice(device:Device, update) {
        await this.fs.doc('lights/' + device.bleId).update(update)
    }

    async updateAllPlaylists() {
        for (let device of this.devices) {
            this.buildProgram(device)
        }
    }

    getDevicesInfo(user) {
        this.subscription = this.fs.collection('lights', result => result.where('users', 'array-contains', user.uid)).valueChanges().subscribe(
            result => {
                console.log('Got devices ' + result.length)
                if (result.length > 0) {
                    for (let item of result) {
                        this.addOrUpdateDevice(item)
                    }
                }
                this.devicesEvent.emit('loaded')
            })
    }

    private addOrUpdateDevice(data) {
        let found = false
        for (let device of this.devices) {
            if (device.bleId == data['bleId']) {
                found = true
                device.ip = data['ip']
                device.appVersion = data['appVersion']
                device.lastPing = data['lastPing']
                device.localTime = data['localTime']
                device.updating = data['updating']
            }
        }

        if (found == false) {
            let newDevice = new Device(data)
            newDevice.bleServiceId = ''
            this.devices.push(newDevice)
            this.alive(newDevice.bleId)
        }
    }

    async alive(bleId) {
        let url = 'https://us-central1-emolights-222122.cloudfunctions.net/relayCommand2?bleId='+bleId+'&command=alive&data=a'

        console.log(url)
        await this.http.get(url).subscribe(
            response => {
                console.log('ping worked')
            },
            error => {
                console.log('error pinging ', error)
            }
        )
    }


    getFavorites(user) {
        this.subscription = this.fs.collection('personal', result => result.where('users', 'array-contains', user.uid)).valueChanges().subscribe(
            result => {
                if (result.length > 0) {
                    this.favorites = new Playlist(result[0] as object)
                } else {
                    this.createFavoritePlaylist()
                }
            })
    }

    async createFavoritePlaylist() {
        let id = this.fs.createId()
        let favorite = new Playlist()
        favorite.users.push(this.user.uid)
        favorite.id = id
        favorite.index = -1
        favorite.epoch = moment().unix()
        this.favorites = favorite
        return await this.fs.doc('personal/' + id).set(favorite.getDict())
    }


    async buildProgram(device) {
        console.log('update playlists')
        if (this.isDev && this.onMobile == false)
            return

        return new Promise((resolve, reject) => {
            let url:string = 'https://us-central1-emolights-222122.cloudfunctions.net/buildProgram'
            url += '?bleId=' + device.bleId

            console.log('playlist url ' + url)

            let params = new HttpParams()
            params.set('device', '' + device.bleId)

            this.http.get(url, {responseType:'blob', params:params}).subscribe(
                response => {
                    console.log('Updated playlists for devices')
                    resolve(response)
                },
                error => {
                    console.log('error updating playlists')
                    console.log(error)
                    reject(error)
                }
            )
        })
    }

    get seasonsCollection() {
        if (this._seasonsCollection)
            return this._seasonsCollection
        this._seasonsCollection = this.fs.collection('seasons').valueChanges()
        this._seasonsCollection.subscribe(
            result => {
                console.log('got seasons ')
                this._seasons.splice(0, this._seasons.length)
                result.forEach(season => {
                        console.log('got season ' + season.index)
                        this._seasons.push(season)
                    }
                )
            })

        return this._seasonsCollection
    }

    get playlists() {
        if (this._playlists)
            return this._playlists
        this._playlists = this.fs.collection('playlists').valueChanges()
        return this._playlists
    }

    get offSeasonPlaylists() {
        if (this._offSeasonPlaylists)
            return this._offSeasonPlaylists
        this._offSeasonPlaylists = this.fs.collection('playlists', ref => ref.where('isOffSeason', '==', true)).valueChanges()
        return this._offSeasonPlaylists
    }

    get seasons() {
        let temp = this.seasonsCollection
        return this._seasons
    }
}