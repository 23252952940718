import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {HomeSession} from './homeSession';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(protected router: Router, protected homeSession:HomeSession) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.homeSession.user == null) {
            console.log('set route', state.url)
            this.homeSession.firstRoute =  state.url

            this.router.navigate(['/']);
            return false
        }
        return true
    }
}
