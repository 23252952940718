/**
 *  2018 Copyright emo lights.
 */
import {Model} from './model'

export class Home extends Model {
    _name = 'home'

    timezone:number = 0
    home:string = 'home'
    users = []
    configured = false
    wifiNames:any[] = []
    wifiPasswords:{} = {}
    devices:any[] = []
    uid:string = ''
    lastUpdate:number = 0


    constructor(data:object = null) {
        super()
        console.log("initializing model")
        this.initData()
        this.setDict(data)
    }
}
