import {
    Component,
    ViewChild,
    ElementRef,
    AfterViewInit,
    Input,
    Output,
    SimpleChanges,
    OnChanges,
    EventEmitter,
    HostListener
} from '@angular/core'
import {RGBColor} from '../color-slider/color-slider.component'

@Component({
    selector:'app-color-palette',
    templateUrl:'./color-palette.component.html',
    styleUrls:['./color-palette.component.css']
})
export class ColorPaletteComponent implements AfterViewInit, OnChanges {
    @Input()
    startColor:RGBColor

    @Input()
    hueColor:RGBColor

    @Output()
    rgbColor:EventEmitter<RGBColor> = new EventEmitter(true)

    @ViewChild('canvas')
    canvas:ElementRef<HTMLCanvasElement>

    private ctx:CanvasRenderingContext2D

    private mousedown:boolean = false

    public selectedPosition:{ x:number; y:number }

    ngAfterViewInit() {
        this.updateStartPosition()
        this.draw()
    }


    draw() {
        if (!this.canvas)
            return

        if (!this.ctx) {
            this.ctx = this.canvas.nativeElement.getContext('2d')
        }
        const width = this.canvas.nativeElement.width
        const height = this.canvas.nativeElement.height

        this.ctx.fillStyle = this.hueColor.toCanvasString() || 'rgba(255,255,255,1)'
        this.ctx.fillRect(0, 0, width, height)

        const whiteGrad = this.ctx.createLinearGradient(0, 0, width, 0)
        whiteGrad.addColorStop(0, 'rgba(255,255,255,1)')
        whiteGrad.addColorStop(1, 'rgba(255,255,255,0)')

        this.ctx.fillStyle = whiteGrad
        this.ctx.fillRect(0, 0, width, height)

        const blackGrad = this.ctx.createLinearGradient(0, 0, 0, height)
        blackGrad.addColorStop(0, 'rgba(0,0,0,0)')
        blackGrad.addColorStop(1, 'rgba(0,0,0,1)')

        this.ctx.fillStyle = blackGrad
        this.ctx.fillRect(0, 0, width, height)

        if (this.selectedPosition) {
            this.ctx.strokeStyle = 'white'
            this.ctx.fillStyle = 'white'
            this.ctx.beginPath()
            this.ctx.arc(this.selectedPosition.x, this.selectedPosition.y, 10, 0, 2 * Math.PI)
            this.ctx.lineWidth = 5
            this.ctx.stroke()
        }
    }

    ngOnChanges(changes:SimpleChanges) {
        if (changes['startColor']) {
            this.updateStartPosition()
            this.draw()
        }

        if (changes['hueColor']) {
            this.draw()
            const pos = this.selectedPosition
            if (pos) {
                console.log(pos.x, pos.y)
                this.emitColor(pos.x, pos.y)
            }
        }
    }

    @HostListener('window:mouseup', ['$event'])
    onMouseUp(evt:MouseEvent) {
        this.mousedown = false
    }

    onMouseDown(evt:MouseEvent) {
        this.mousedown = true
        let ox = evt.offsetX
        let oy = evt.offsetY

        ox = Math.min(249, ox)
        oy = Math.min(249, oy)

        ox = Math.max(1, ox)
        oy = Math.max(1, oy)

        this.selectedPosition = {x:ox, y:oy}
        this.draw()
        this.emitColor(ox, oy)
    }

    onMouseMove(evt:MouseEvent) {
        if (this.mousedown) {
            let ox = evt.offsetX
            let oy = evt.offsetY

            ox = Math.min(249, ox)
            oy = Math.min(249, oy)

            ox = Math.max(1, ox)
            oy = Math.max(1, oy)

            this.selectedPosition = {x:ox, y:oy}
            this.draw()
            this.emitColor(ox, oy)
        }
    }

    onTouch(e:TouchEvent) {
        let touches = e.changedTouches
        let x = touches[0].pageX
        let y = touches[0].pageY
        let rect = this.canvas.nativeElement.getBoundingClientRect()
        let ox = x - rect.left
        let oy = y - rect.top

        ox = Math.min(249, ox)
        oy = Math.min(249, oy)

        ox = Math.max(1, ox)
        oy = Math.max(1, oy)

        this.selectedPosition = {x:ox, y:oy}
        this.draw()
        this.emitColor(ox, oy)
    }


    emitColor(x:number, y:number) {
        this.rgbColor.emit(this.getRGBAtPosition(x, y))
    }

    getColorAtPosition(x:number, y:number) {
        const imageData = this.ctx.getImageData(x, y, 1, 1).data
        return 'rgba(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)'
    }

    getRGBAtPosition(x:number, y:number) {
        const imageData = this.ctx.getImageData(x, y, 1, 1).data
        return new RGBColor(imageData[0], imageData[1], imageData[2])
    }

    private updateStartPosition() {
        if (!this.canvas)
            return

        if (this.startColor) {
            let hsv = this.startColor.rgb2hsv()
            const width = this.canvas.nativeElement.width
            const height = this.canvas.nativeElement.height

            let x = (hsv.s / 100.0) * width
            let y = height - ((hsv.v / 100.0) * height)

            x = Math.min(249, x)
            y = Math.min(249, y)

            x = Math.max(1, x)
            y = Math.max(1, y)

            this.selectedPosition = {x:x, y:y}
        }
    }
}
