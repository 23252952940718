import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core'

export class RGBColor {
    r = 0
    g = 0
    b = 0

    constructor(r = 0, g = 0, b = 0) {
        this.r = r
        this.g = g
        this.b = b
    }

    toDict() {
        return {r:this.r, g:this.g, b:this.b}
    }

    toString() {
        return this.r + ',' + this.g + ',' + this.b
    }

    fromString(color) {
        let channels = color.split(',')
        this.r = Number(channels[0])
        this.g = Number(channels[1])
        this.b = Number(channels[2])
    }

    toCanvasString() {
        return 'rgba(' + this.r + ',' + this.g + ',' + this.b + ',1)'
    }

    saturate() {
        let hsv = this.rgb2hsv()
        this.fromHSV(hsv.h, 100, 100)
    }

    clone() {
        return new RGBColor(this.r, this.g, this.b)
    }

    fromHSV(h, s, v) {
        h = h / 360
        s = s / 100
        v = v / 100

        var r, g, b, i, f, p, q, t

        if (arguments.length === 1) {
            s = h.s, v = h.v, h = h.h
        }
        i = Math.floor(h * 6)
        f = h * 6 - i
        p = v * (1 - s)
        q = v * (1 - f * s)
        t = v * (1 - (1 - f) * s)
        switch (i % 6) {
            case 0:
                r = v, g = t, b = p
                break
            case 1:
                r = q, g = v, b = p
                break
            case 2:
                r = p, g = v, b = t
                break
            case 3:
                r = p, g = q, b = v
                break
            case 4:
                r = t, g = p, b = v
                break
            case 5:
                r = v, g = p, b = q
                break
        }

        this.r = Math.round(r * 255)
        this.g = Math.round(g * 255)
        this.b = Math.round(b * 255)
    }

    rgb2hsv() {
        let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn
        rabs = this.r / 255
        gabs = this.g / 255
        babs = this.b / 255

        v = Math.max(rabs, gabs, babs),
            diff = v - Math.min(rabs, gabs, babs)
        diffc = c => (v - c) / 6 / diff + 1 / 2
        percentRoundFn = num => Math.round(num * 100) / 100
        if (diff == 0) {
            h = s = 0
        } else {
            s = diff / v
            rr = diffc(rabs)
            gg = diffc(gabs)
            bb = diffc(babs)

            if (rabs === v) {
                h = bb - gg
            } else if (gabs === v) {
                h = (1 / 3) + rr - bb
            } else if (babs === v) {
                h = (2 / 3) + gg - rr
            }
            if (h < 0) {
                h += 1
            } else if (h > 1) {
                h -= 1
            }
        }
        return {
            h:Math.round(h * 360),
            s:percentRoundFn(s * 100),
            v:percentRoundFn(v * 100)
        }
    }

    fromNumber(c) {
        this.r = (c & 0xff0000) >> 16
        this.g = (c & 0x00ff00) >> 8
        this.b = (c & 0x0000ff)
    }

    toNumber() {
        return (this.r << 16) + (this.g << 8) + (this.b)
    }

    componentToHex(c) {
        let hex = c.toString(16)
        return hex.length == 1 ? "0" + hex : hex
    }

    toHex() {
        return "#" + this.componentToHex(this.r) + this.componentToHex(this.g) + this.componentToHex(this.b)
    }

}

@Component({
    selector:'app-color-slider',
    templateUrl:'./color-slider.component.html',
    styleUrls:['./color-slider.component.css']
})
export class ColorSliderComponent implements AfterViewInit, OnChanges {
    @Input()
    startColor:RGBColor

    @ViewChild('canvas')
    canvas:ElementRef<HTMLCanvasElement>

    @Output()
    rgbColor:EventEmitter<RGBColor> = new EventEmitter()

    private ctx:CanvasRenderingContext2D
    private mousedown:boolean = false
    private selectedPosition:number

    ngAfterViewInit() {
        this.updatePosition()
    }

    ngOnChanges(changes:SimpleChanges) {
        if (changes['startColor']) {
            this.updatePosition()
        }
    }

    updatePosition() {
        if (!this.canvas)
            return

        if (this.startColor) {
            let hsv = this.rgb2hsv(this.startColor.r, this.startColor.g, this.startColor.b)
            const width = this.canvas.nativeElement.width
            this.selectedPosition = (hsv['h'] / 360.0) * width
            this.selectedPosition = Math.max(1, this.selectedPosition)
            this.selectedPosition = Math.min(width - 1, this.selectedPosition)
            this.draw()
        }
    }

    draw() {
        if (!this.ctx) {
            this.ctx = this.canvas.nativeElement.getContext('2d')
        }
        const width = this.canvas.nativeElement.width
        const height = this.canvas.nativeElement.height

        this.ctx.clearRect(0, 0, width, height)

        const gradient = this.ctx.createLinearGradient(0, 0, width, 0)
        gradient.addColorStop(0, 'rgba(255, 0, 0, 1)')
        gradient.addColorStop(0.17, 'rgba(255, 255, 0, 1)')
        gradient.addColorStop(0.34, 'rgba(0, 255, 0, 1)')
        gradient.addColorStop(0.51, 'rgba(0, 255, 255, 1)')
        gradient.addColorStop(0.68, 'rgba(0, 0, 255, 1)')
        gradient.addColorStop(0.85, 'rgba(255, 0, 255, 1)')
        gradient.addColorStop(1, 'rgba(255, 0, 0, 1)')

        this.ctx.beginPath()
        this.ctx.rect(0, 0, width, height)

        this.ctx.fillStyle = gradient
        this.ctx.fill()
        this.ctx.closePath()

        if (this.selectedPosition) {
            this.ctx.beginPath()
            this.ctx.strokeStyle = 'white'
            this.ctx.lineWidth = 5
            this.ctx.rect(this.selectedPosition - 5, 0, 10, height)
            this.ctx.stroke()
            this.ctx.closePath()
        }
    }

    @HostListener('window:mouseup', ['$event'])
    onMouseUp(evt:MouseEvent) {
        this.mousedown = false
    }

    onMouseDown(evt:MouseEvent) {
        this.mousedown = true
        this.selectedPosition = evt.offsetX
        this.emitColor(evt.offsetX, evt.offsetY)
    }

    onMouseMove(evt:MouseEvent) {
        if (this.mousedown) {

            this.selectedPosition = evt.offsetX
            this.emitColor(evt.offsetX, evt.offsetY)
        }
    }
    onTouch(e:TouchEvent) {
        let touches = e.changedTouches
        let x = touches[0].pageX
        let rect = this.canvas.nativeElement.getBoundingClientRect()
        let ox = x - rect.left

        ox = Math.min(249, ox)

        ox = Math.max(1, ox)

        this.selectedPosition = ox
        this.draw()
        this.emitColor(ox, 5)
    }

    
    emitColor(x:number, y:number) {
        const width = this.canvas.nativeElement.width
        if (x > width - 1)
            return
        if (x < 1)
            return

        this.draw()

        this.rgbColor.emit(this.getRGBAtPosition(x, 5))
    }

    getColorAtPosition(x:number, y:number) {
        const imageData = this.ctx.getImageData(x, y, 1, 1).data
        return 'rgba(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)'
    }

    getRGBAtPosition(x:number, y:number) {
        const imageData = this.ctx.getImageData(x, y, 1, 1).data
        return new RGBColor(imageData[0], imageData[1], imageData[2])
    }

    rgb2hsv(r, g, b) {
        let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn
        rabs = r / 255
        gabs = g / 255
        babs = b / 255
        v = Math.max(rabs, gabs, babs),
            diff = v - Math.min(rabs, gabs, babs)
        diffc = c => (v - c) / 6 / diff + 1 / 2
        percentRoundFn = num => Math.round(num * 100) / 100
        if (diff == 0) {
            h = s = 0
        } else {
            s = diff / v
            rr = diffc(rabs)
            gg = diffc(gabs)
            bb = diffc(babs)

            if (rabs === v) {
                h = bb - gg
            } else if (gabs === v) {
                h = (1 / 3) + rr - bb
            } else if (babs === v) {
                h = (2 / 3) + gg - rr
            }
            if (h < 0) {
                h += 1
            } else if (h > 1) {
                h -= 1
            }
        }
        return {
            h:Math.round(h * 360),
            s:percentRoundFn(s * 100),
            v:percentRoundFn(v * 100)
        }
    }
}
