import {Component, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {Device} from '../../../models/device'
import {Observable} from 'rxjs/Observable'
import {AngularFirestore} from '@angular/fire/firestore'


@Component({
    selector:'chooseBrightness',
    templateUrl:'chooseBrightness.html'
})
export class ChooseBrightness implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    trigger = null
    playlists:Observable<any[]>
    demoIndices = {}
    brightness:number = 100

    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        public modalController:ModalController) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.trigger = this.homeSession.selectedTrigger
        this.brightness = this.homeSession.selectedTrigger['values']['brightness']
    }

    ngOnDestroy() {
    }


    async closeModal() {
        this.homeSession.selectedTrigger['values']['brightness'] = this.brightness
        await this.store()
        await this.modalController.dismiss('closeAll')
    }

    async store() {
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setBrightness(value:any) {
        console.log('setting value to ' + value)
        this.homeSession.selectedTrigger['values']['brightness'] = value
    }
}

