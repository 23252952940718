import {HomeSession} from './homeSession'
import {Injectable} from '@angular/core'
import {Device} from '../models/device'
import {WebSocketSubject} from 'rxjs/webSocket'


export class WebSocketConnection {
    messages:string[] = []

    get connected():boolean {
        return this.device._connectedWifi
    }

    set connected(value:boolean) {
        this.device._connectedWifi = value
    }

    ws:WebSocketSubject<any>
    socket:WebSocket

    connecting = false
    gotMessage = false
    sending = false


    constructor(public device:Device) {
        this.device = device
        this.device._connectedWifi = false
    }

    connect() {
        if (this.device.updating)
            return

        if (this.connecting) {
            return
        }

        if (this.connected)
            return

        if (!this.device.ip)
            return

        this.connecting = true

        if (this.socket) {
            this.socket.close()
            this.socket.removeEventListener('open', null)
            this.socket.removeEventListener('close', null)
            this.socket.removeEventListener('error', null)
            this.socket.removeEventListener('message', null)
        }

        let url = 'ws://' + this.device.ip + ':81'

        try {
            console.log('WS Connecting to ' + this.device.ip)
            this.socket = new WebSocket(url)
        } catch (e) {
            this.connecting = false
            console.log('Error creating socket connection ' + this.device.ip)
            return
        }

        for(let prop in this.socket) {
            if (prop.startsWith('on')) {
                this.socket.addEventListener(prop.substr(2), (event) => {
                    // console.log('Got Event ' + event.type)
                    this.connecting = false
                })
            } else {
            }
        }

        this.socket.addEventListener('*', (event) => {
            console.log('Event ' + event.type)
            this.connecting = false
        })

        this.socket.addEventListener('open', (event) => {
            console.log('WS Connected' + this.device.ip)
            this.connecting = false
            this.sending = false
            this.next(null, false)
        })


        this.socket.addEventListener('close', (event) => {
            console.log('WS Closed ' + this.device.ip)
            this.sending = false
            this.connected = false
        })

        this.socket.addEventListener('error', (event) => {
            console.log('WS Error ' + this.device.ip, + event.toString())
            this.sending = false
            this.connecting = false
        })

        this.socket.addEventListener('message', (event) => {
            this.connected = true
            this.connecting = false
            this.gotMessage = true
            this.sending = false
            if (event.data != '.')
                this.device.nextMsg(event.data.toString())

            // send any remaining msgs
            this.next('', false)
        })
    }

    next(message:string, okToSkip:boolean) {

        if (this.sending) {
            if ((okToSkip == false) && (message))
                this.messages.push(message)
            return
        }

        if (message) {
            if ((this.messages.length > 0) && (this.messages[this.messages.length - 1] == message))
                return

            this.messages.push(message)
        }

        if (this.messages.length == 0)
            return

        message = this.messages.shift()

        console.log('Sending message ' + message + ' ' + this.messages)
        this.sending = true
        this.socket.send(message)
    }
}

@Injectable({
    providedIn:'root'
})
export class WebSocketService {
    connections:any = {}

    constructor(public homeSession:HomeSession) {
        this.checkConnections()
    }

    checkConnections() {
        for (let device of this.homeSession.devices) {
            let connection = this.getConnection(device)

            if (connection) {
                setTimeout(() => {
                    this.checkConnection(connection)
                }, 10)

            }
        }

        setTimeout(() => {
            this.checkConnections()
        }, 5000)
    }

    getConnection(device:Device) {
        if (this.connections[device.bleId])
            return this.connections[device.bleId]

        let connection:WebSocketConnection = new WebSocketConnection(device)
        connection.connect()

        this.connections[device.bleId] = connection
        return connection
    }


    public checkConnection(connection:WebSocketConnection) {
        if (connection.connected) {
            if (connection.gotMessage) {
                connection.gotMessage = false
            } else {
                // Device must have disconnected
                connection.connected = false
            }
        }

        if (connection.connected == false) {
            connection.connect()
        }
    }

    public sendingAsync(device, msg, okToSkip = true) {
        let connection:WebSocketConnection = this.getConnection(device)
        connection.next(msg, okToSkip)
    }


    public publishToAllLocalDevices(msg) {
        for (let device of this.homeSession.devices) {
            setTimeout(() => {
                this.sendingAsync(device, msg)
            }, 1)
        }
    }

    public publishMessage(device, msg, okToSkip:boolean = true) {
        setTimeout(() => {
            this.sendingAsync(device, msg, okToSkip)
        }, 1)
    }

    public publishToAllConnectedDevices(msg) {
        for (let device of this.homeSession.devices) {
            if (device._connectedWifi == false)
                continue
            setTimeout(() => {
                this.sendingAsync(device, msg)
            }, 1)
        }
    }
}
