import {Component, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {Device} from '../../../models/device'
import {Observable} from 'rxjs/Observable'
import {AngularFirestore} from '@angular/fire/firestore'
import {ColorEvent} from 'ngx-color'
import {RGBColor} from './colorPicker/color-slider/color-slider.component'


@Component({
    selector:'chooseColors',
    templateUrl:'chooseColors.html'
})
export class ChooseColors implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    trigger = null
    playlists:Observable<any[]>
    demoIndices = {}
    hue:string = 'rgba(237,0,255,1)'
    color:string
    startColor:RGBColor = new RGBColor(254, 0, 255)
    hueColor:RGBColor = new RGBColor(254, 0, 255)
    rgbColor:RGBColor = new RGBColor(255, 0, 255)
    colors:RGBColor[] = []
    selectedIndex = -1

    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        public modalController:ModalController) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.trigger = this.homeSession.selectedTrigger
        this.rgbColor = this.startColor
        this.switchToColor(this.trigger['values']['colors'].length - 1)
    }

    ngOnDestroy() {
    }


    async closeModal() {
        await this.store()
        await this.modalController.dismiss('closeAll')
    }

    async store() {
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setBrightness(value:any) {
        console.log('setting value to ' + value)
        this.homeSession.selectedTrigger['values']['brightness'] = value
    }

    changeComplete($event:ColorEvent) {
        console.log($event.color)
    }

    setHue($event:string) {
        this.hue = $event
        this.color = $event
        console.log('hue ' + this.hue)
    }

    setRGBHueColor($event:RGBColor) {
        this.rgbColor = $event
        this.hueColor = this.rgbColor.clone()
        console.log('rgbhuecolor ',this.hueColor.toString())
    }

    setRGBColor($event:RGBColor) {
        this.rgbColor = $event
        if (this.selectedIndex > -1)
            this.trigger['values']['colors'][this.selectedIndex] = this.rgbColor.toNumber()
    }

    setColor($event:string) {
        this.color = $event
    }

    addColor() {
        console.log('this.rgbColor' + this.rgbColor)
        this.trigger['values']['colors'].push(this.rgbColor.toNumber())
        this.selectedIndex = this.trigger['values']['colors'].length - 1
    }


    setSwatchColor(color) {
        let rgbColor:RGBColor = new RGBColor()
        rgbColor.fromNumber(color)
        return rgbColor.toHex()
    }

    removeColor(index) {
        this.trigger['values']['colors'].splice(index, 1)
        this.selectedIndex = Math.max(0, index)
        this.selectedIndex = Math.min(this.trigger['values']['colors'].length - 1, index)
        this.switchToColor(this.selectedIndex)
    }

    switchToColor(index) {
        this.selectedIndex = index
        let color = this.trigger['values']['colors'][index]

        let newColor = new RGBColor()
        newColor.fromNumber(color)
        this.rgbColor = newColor.clone()

        this.startColor = newColor.clone()
        newColor.saturate()
        this.hueColor = newColor.clone()

        this.color = color
        console.log(this.hueColor.toString())
    }

}

