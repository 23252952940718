/**
 *  2018 Copyright emo lights.
 */
import {Model} from './model'


export enum TriggerTypes {
    date = 'date',
    sport = 'sport',
    device = 'device',
    none = 'none'
}

export class Playlist extends Model {
    _name = 'playlist'

    playlist:string = ''
    index:Number = 0
    id:string = ''
    url:string = ''
    triggerType:string = TriggerTypes.date
    startMonth:number = 1
    startDay:number = 1
    endMonth:number = 2
    endDay:number = 2
    startTime:number = 1
    endTime:number = 1
    shows:string[] = []
    users:string[] = []
    season:string = ''
    epoch:number = 0

    constructor(data:object = null) {
        super()
        this.initData()
        this.users = []
        this.shows = []
        this.setDict(data)
    }
}
