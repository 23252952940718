import {Component, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {DeviceName} from './deviceName'
import {DeviceWiFi} from './deviceWiFi'
import {DeviceReset} from './deviceReset'
import {DeviceSeasons} from '../auto/deviceSeasons'


@Component({
    selector:'deviceConfig',
    templateUrl:'deviceConfig.html'
})
export class DeviceConfig implements OnInit, OnDestroy {
    device:Device
    modal = null
    visible:boolean = true

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
    }

    ngOnDestroy() {
    }

    async closeModal(data = '', sleep = 0) {
        await this.homeSession.sleep(sleep)
        await this.modalController.dismiss(data)
    }

    async rename() {
        this.modal = await this.modalController.create({
            component:DeviceName,
            animated:true
        })

        this.modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.visible = false
            this.closeModal('closeAll', 10)
        })

        return await this.modal.present()
    }

    async setWiFi() {
        this.modal = await this.modalController.create({
            component:DeviceWiFi,
            animated:true
        })

        this.modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.visible = false
            this.closeModal('closeAll', 10)
        })

        return await this.modal.present()
    }


    async reset() {
        this.modal = await this.modalController.create({
            component:DeviceReset,
            animated:true
        })

        this.modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.visible = false
            this.closeModal('closeAll', 10)
        })

        return await this.modal.present()
    }


    async checkForUpdate() {
        await this.commandService.publish(this.device, 'forceUpdate', false)
        await this.closeModal('closeAll', 10)
    }

    async emptyCache() {
        await this.commandService.publish(this.device, 'emptyCache', false)
        await this.closeModal('closeAll', 10)
    }

    async configureShows() {

        this.router.navigateByUrl('/configure')
        await this.closeModal('closeAll', 10)

        // const modal = await this.modalController.create({
        //     component:ShowDevPlaylists,
        //     animated:true
        // })
        // return await modal.present()
    }

    async seasons() {
        this.modal = await this.modalController.create({
            component:DeviceSeasons,
            animated:true
        })

        this.modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.visible = false
            this.closeModal('closeAll', 10)
        })
        return await this.modal.present()
    }
}

