import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {DeviceTriggers} from './deviceTriggers'
import {DeviceSeasons} from './deviceSeasons'


@Component({
    selector:'deviceAutoSchedule',
    templateUrl:'deviceAutoSchedule.html'
})
export class DeviceAutoSchedule implements OnInit, OnDestroy{
    subscription:Subscription = null
    device:Device
    timerKeys:Number[]

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController: ModalController,
        private zone: NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.timerKeys = []
        for (let trigger of Object.entries(this.device.triggers)) {
            this.timerKeys.push(Number.parseInt(trigger['time']))
        }

    }

    ngOnDestroy() {
    }

    closeModal() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    timerToTime(timer:Number) {
        if(timer == 0)
            return 'Midnight'
        if(timer == -1)
            return 'Sunrise'
        if(timer == -2)
            return 'Noon'
        if(timer == -3)
            return 'Sunset'
    }

    async playlists() {
        this.setAutoSchedule()
        const modal = await this.modalController.create({
            component: DeviceSeasons,
            animated: true
        });
        return await modal.present();
    }

    setAutoSchedule() {
        this.device.mode = this.device._AUTO_MODE
        this.commandService.publish(this.device, 'mode/' + this.device.mode)
    }

    async schedules() {
        this.setAutoSchedule()
        const modal = await this.modalController.create({
            component: DeviceTriggers,
            animated: true
        });
        return await modal.present();
    }
}

