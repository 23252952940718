import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {ActionLabels, Days, Device} from '../../../models/device'
import {HomeSession} from '../../../services/homeSession'
import moment from 'moment'
import {ChoosePlaylist} from './choosePlaylist'
import {ChooseBrightness} from './chooseBrightness'
import {ChooseColors} from './chooseColors'
import {DeviceTriggerConfigAction} from './deviceTriggerConfigAction'
import {DeviceTriggerConfigTime} from './deviceTriggerConfigTime'


@Component({
    selector:'deviceTriggerConfig',
    templateUrl:'deviceTriggerConfig.html'
})
export class DeviceTriggerConfig implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    schedule:any
    originalSchedule:any
    datetime = moment()

    actions:string[] = ['powerOn',
        'powerOff',
        'brightness',
        'colors',
        'playlist']

    actionLabels = ActionLabels
    dayLabels = Days
    trigger = {action:'', days:['1', '2', '3', '4', '5', '6', '7']}

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        public modalController:ModalController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.trigger = this.homeSession.selectedTrigger
        this.schedule = this.trigger['time']
        this.originalSchedule = this.schedule

        if (Number(this.schedule) > -1) {
            let hour:number = Math.floor(this.schedule / 60)
            let minutes:number = this.schedule - (hour * 60)
            this.datetime.set('hour', hour)
            this.datetime.set('minutes', minutes)
        }
    }

    ngOnDestroy() {
    }

    async closeModal(data = '', sleep = 0) {
        await this.homeSession.sleep(sleep)

        if (data == 'closeAll') {
            await this.modalController.dismiss(data)
            return
        }

        await this.store()
        this.homeSession.buildProgram(this.device)
        await this.modalController.dismiss()
    }


    async store() {
        if (this.device.triggers.indexOf(this.trigger) == -1)
            return
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setTime(value:string) {
        let parts = value.split(':')
        let hour:number = Number(parts[0])
        let minutes:number = Number(parts[1])
        this.datetime.set('hour', hour)
        this.datetime.set('minutes', minutes)
        this.schedule = (hour * 60) + minutes
        this.setTriggersTime(this.schedule)
    }

    setTriggersTime(number:number) {
        console.log('setting time to ', number)
        this.trigger['time'] = number
        this.store()
    }

    removeTrigger() {
        this.device.triggers.splice(this.device.triggers.indexOf(this.trigger), 1)
        this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
        this.homeSession.buildProgram(this.device)
        this.modalController.dismiss('closeAll')
    }

    toggleDay(day:string) {
        if (this.trigger.days.indexOf(day) == -1)
            this.trigger.days.push(day)
        else
            this.trigger.days.splice(this.trigger.days.indexOf(day), 1)
        this.trigger.days.sort()
    }

    async chooseColors() {
        const modal = await this.modalController.create({
            component:ChooseColors,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        return await modal.present()
    }

    async choosePlaylist() {
        const modal = await this.modalController.create({
            component:ChoosePlaylist,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        await modal.present()
    }

    async chooseBrightness() {
        const modal = await this.modalController.create({
            component:ChooseBrightness,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        return await modal.present()
    }

    async chooseAction() {
        const modal = await this.modalController.create({
            component:DeviceTriggerConfigAction,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.closeModal('closeAll', 10)
        })

        return await modal.present()
    }

    async chooseTime() {
        const modal = await this.modalController.create({
            component:DeviceTriggerConfigTime,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        return await modal.present()
    }
}

