import {Component} from '@angular/core'
import {Platform} from '@ionic/angular'

import firebase from 'firebase/app';
import 'firebase/auth'

import {Observable} from 'rxjs/Observable'
import {AngularFireAuth} from '@angular/fire/auth'
import {HomeSession} from '../../services/homeSession'

// import {InAppPurchase2} from '@ionic-native/in-app-purchase-2/ngx'
import {Router} from '@angular/router';
import {cfaSignOut} from 'capacitor-firebase-auth';


@Component({
    selector:'page-profile',
    templateUrl:'profile.html',
})
export class ProfilePage {

    user:Observable<firebase.User>
    loggedIn:boolean = false
    onMobile:boolean = false
    appVersion:string = "2021.2.02"

    constructor(private afAuth:AngularFireAuth,
                private platform:Platform,
                public router:Router,
                private homeSession:HomeSession,
                // private store:InAppPurchase2
    ) {

        this.user = this.afAuth.authState
        this.afAuth.authState.subscribe(user => this.updateLoginState(user))
        this.platform.ready().then((readySource) => {
            if (readySource == 'cordova') {
                this.onMobile = true
            }
        })
    }

    updateLoginState(user) {
        if (user == null) {
            console.log('No user yet')
            this.loggedIn = false
        } else {
            this.loggedIn = user.uid != ''
            console.log('Logged-in ' + this.loggedIn)
            this.user = user
            this.homeSession.user = user
        }

        if (this.loggedIn == false) {
            this.router.navigateByUrl('/login')
        }
    }


    async signOut() {
        console.log('Logging out')
        this.loggedIn = false;
        this.user = null
        this.homeSession.user = null

        if (this.onMobile) {
            cfaSignOut().subscribe()
            console.log('Logging out done')
        }

        await this.afAuth.signOut()
        console.log('Logging out done 2')
    }
}
