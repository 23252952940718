import {HomeSession} from './homeSession'
import {Injectable} from '@angular/core'
import {WebSocketService} from './webSocketService'
import {BleService} from './bleService'


@Injectable({
    providedIn:'root'
})
export class CommandService {
    VIA_WIFI = 1
    VIA_BLUETOOTH = 2
    NONE = 0

    foo:String = 'test'

    constructor(public homeSession:HomeSession,
                public webSocketService:WebSocketService,
                public bleService:BleService) {
    }

    public publishToConnectedDevices(msg) {
        for (let device of this.homeSession.devices) {
            if (device._connectedWifi)
                this.webSocketService.publishMessage(device, msg)

            else if (device._connectedBLE) {
                this.bleService.publishMessage(device, msg)
            } else {
                console.log('Device not connected ', device.ip)
            }
        }
    }

    public async publish(device, msg, okToSkip:boolean = true) {
        if (device._connectedWifi) {
            await this.webSocketService.publishMessage(device, msg, okToSkip)
            return this.VIA_WIFI
        } else if (device._connectedBLE) {
            await this.bleService.publishMessage(device, msg)
            return this.VIA_BLUETOOTH
        }
        console.log('Device not connected ', device.ip)
        return this.NONE

    }
}
