/**
 *  2018 Copyright emo lights.
 */
import {Model} from './model'
import {Observable, Subscriber} from 'rxjs'
import {RGBColor} from '../pages/devices/auto/colorPicker/color-slider/color-slider.component'

export const ActionLabels = {
    'powerOn':'Turn On',
    'powerOff':'Turn Off',
    'brightness':'Set Brightness',
    'playlist':'Select a playlist',
    'colors':'Set to custom colors'
}

export const Days = {
    '1':'Sun',
    '2':'Mon',
    '3':'Tue',
    '4':'Wed',
    '5':'Thu',
    '6':'Fri',
    '7':'Sat'
}

export class Device extends Model {
    _name = 'device'
    _new = false
    _bleName = ''
    _playlistName = ''

    _OFF_MODE = 0
    _MANUAL_MODE = 1
    _AUTO_MODE = 2
    _COLOR_MODE = 3
    updating = false

    timezone:String = ''
    device:string = ''
    bleId:string = ''
    users:Array<string> = []
    configured = false
    modelInfo:String = '60ft'
    modelYear:Number = 0
    bleServiceId:string = ''
    homeId:string = ''
    uid:string = ''
    lon:Number = 0.0
    lat:Number = 0.0
    seasons:any = {}
    seasonDates:any = {}
    offSeason:number = -1
    trigger = 0

    ip:string = ""
    selectedSeason = ''
    mode = 1
    power:boolean = true
    pause:boolean = true
    playlist = ''

    autoIndex = 0
    manualIndex = 0
    show = ''

    factoryVersion = ''
    factoryInstall = ''

    playingDemoIndex = -1
    showIndex = -1
    lastUpdate = 0
    lastPing = 0
    brightness = 100

    program:string = ''

    triggers:any = []

    _hasWifi:boolean = false
    _connectedWifi:boolean = false
    _connectedBLE:boolean = false

    _busy:boolean = false
    _observable:Observable<string> = null
    _subscriber:Subscriber<string> = null

    appVersion:string = ''
    localTime:any = null
    seasonsDates:{}
    color:number = 0
    _rgbColors:RGBColor[] = []
    colors:Number[] = []
    colorsFadeSpeed = 100
    colorsAnimateSpeed = 100
    colorsDuration = 100

    constructor(data:object = null) {
        super()
        this.initData()
        this.setDict(data)
        this._observable = new Observable(subscriber => {
            this._subscriber = subscriber
        })
    }

    getObservable():Observable<string> {
        return this._observable
    }

    nextMsg(message) {
        this.parseInfoMessage(message)

        if (this._subscriber && message)
            this._subscriber.next(message)
    }

    parseInfoMessage(message) {
        this.updating = false
        if (message == 'hello')
            return
        if (message == '')
            return
        if (message.startsWith('{') == false) {
            console.log('Not a valid msg ' + message)
            return
        }
        try {
            let parsed = JSON.parse(message)
            console.log(message)

            if (parsed.brightness != null) {
                this.brightness = parsed.brightness
            }
            if (parsed.appVersion != null)
                this.appVersion = parsed.appVersion
            if (parsed.power != null)
                this.power = parsed.power
            if (parsed.localTime != null)
                this.localTime = parsed.localTime
            if (parsed.playlist != null) {
                this.playlist = parsed.playlist
                while (this.playlist.length < 4) {
                    this.playlist = '0' + this.playlist
                }
            }
            if (parsed.showIndex != null)
                this.showIndex = parsed.showIndex
            if (parsed.autoIndex != null)
                this.autoIndex = parsed.autoIndex
            if (parsed.pause != null)
                this.pause = parsed.pause
            if (parsed.colors != null) {
                let colors = parsed.colors.split(' ')
                this.colorsDuration = Number(colors[0])
                this.colorsFadeSpeed = Number(colors[1])
                this.colorsAnimateSpeed = Number(colors[2])
                colors.splice(0, 4)
                this._rgbColors = []
                this.colors = []
                for (let color of colors) {
                    let rgb = new RGBColor()
                    rgb.fromNumber(Number(color))
                    this.colors.push(color)
                    this._rgbColors.push(rgb)
                }
            } else {
                this._rgbColors = []
            }
        } catch (e) {
            console.log('Could not parse msg')
        }
    }
}
