import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {Observable} from 'rxjs/Observable'
import {AngularFirestore} from '@angular/fire/firestore'


@Component({
    selector:'choosePlaylist',
    templateUrl:'choosePlaylist.html'
})
export class ChoosePlaylist implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    playlists:Observable<any[]>
    demoIndices = {}

    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.getPlaylists()
        this.device = this.homeSession.selectedDevice
        console.log('Connected device ' + this.homeSession.selectedDevice.device)
    }

    ngOnDestroy() {
    }


    getPlaylists() {
        this.playlists = this.homeSession.playlists
    }

    closeModal() {
        this.store()
        this.modalController.dismiss('closeAll')
    }

    async store() {
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setPlaylist(item:any) {
        this.homeSession.selectedTrigger['playlist'] = item.index
    }
}

