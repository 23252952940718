import {ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core'
import {ModalController, Platform, ToastController} from '@ionic/angular'
import {Device} from '../../models/device'
import {HomeSession} from '../../services/homeSession'
import {BleConnection, BleService} from '../../services/bleService'
import {Subscription} from 'rxjs'
import {WebSocketService} from '../../services/webSocketService'
import {Router} from '@angular/router'
import moment from 'moment'
import {DeviceDetails} from './deviceDetails'
import {DeviceWiFi} from './config/deviceWiFi'
import {CommandService} from '../../services/commandService'


@Pipe({
    name:'sortDevices',
    pure: false
})
export class SortDevices implements PipeTransform {
    key = ''

    transform(values:any[]) {
        if (values)
            values.sort((a:any, b:any) => {
                return this.sortDevices(a, b, 'device')
            })
        return values
    }

    private sortDevices(a, b, key) {
        if (a[key] == b[key])
            return 0
        if (a[key] < b[key])
            return -1
        return 1
    }
}

@Component({
    selector:'page-devices',
    templateUrl:'devices.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class DevicesPage implements OnInit, OnDestroy {
    subscription:Subscription = null
    gettingDevices = false
    subscriptions = {}

    constructor(
        public homeSession:HomeSession,
        public plt:Platform,
        public router:Router,
        public bleService:BleService,
        private webSocketService:WebSocketService,
        public modalController:ModalController,
        public commandService:CommandService,
        private toastCtrl:ToastController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.viewEnter()
    }

    ngOnDestroy() {
        console.log('Leaving')
        this.cancelSubscriptions()
    }

    viewEnter() {
        if (this.homeSession.onMobile) {
            this.webSocketService.publishToAllLocalDevices('hello')
            this.rescan()
        }
    }

    checkBluetooth() {
    }

    cancelSubscriptions() {
        if (this.subscription != null) {
            this.subscription.unsubscribe()
            this.subscription = null
        }

        for (let device of this.homeSession.devices) {
            if (this.subscriptions[device.bleId])
                this.subscriptions[device.bleId].unsubscribe()
        }
    }

    async getDevices() {
        if (this.gettingDevices) {
            console.log('Skipping - Still getting devices')
            return
        }

        this.gettingDevices = true

        let devices = this.homeSession.devices.concat([])

        for (let device of devices) {
            await this.connectViaBluetooth(device)
        }

        console.log('Done getting devices')
        this.gettingDevices = false
    }

    async connectViaBluetooth(device:Device) {
        if (this.subscriptions[device.bleId])
            return

        let bleConnection:BleConnection = this.bleService.getBleConnectionForDevice(device)
        if (bleConnection) {
            this.subscriptions[device.bleId] = bleConnection.connectionEvent.subscribe(result => {
                this.refreshScreen()
                if (result) {
                    console.log('Got connected event', result)
                    this.updateDeviceFromBLE(device, bleConnection)
                }
            })
        }
    }

    refreshScreen() {
        this.zone.run(() => {
            console.log('force update the screen')
        })
    }

    async updateDeviceFromBLE(device:Device, bleConnection:BleConnection) {
        await bleConnection.sendMessage("hello")
        this.refreshScreen()
    }

    async configureDevice(device:Device) {
        console.log('Presenting modal')
        this.homeSession.selectedDevice = device

        console.log('dev device ' + device.device)
        console.log('dev homeid ' + device.homeId)
        console.log('dev timezone ' + device.timezone)


        const modal = await this.modalController.create({
            component:DeviceWiFi,
            animated:true
        })
        return await modal.present()
    }

    async presentModal() {
        console.log('Presenting modal')
        const modal = await this.modalController.create({
            component:DeviceDetails,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        return await modal.present()
    }

    async editDevice(device:Device) {
        this.homeSession.selectedDevice = device
        await this.presentModal()
    }

    async rescan() {

        if (this.bleService.scanning)
            return

        if (this.homeSession.onMobile)
            await this.bleService.init()

        await this.getDevices()
    }

    formatDate(lastUpdate:number) {
        return moment(lastUpdate * 1000).format("M/D/YYYY H:mm")
    }

    togglePower(device) {
        device.power = !device.power
        this.commandService.publish(device, 'power/' + (device.power ? '1':'0'))
        this.homeSession.updateDevice(device, {'power':device.power})
    }

}
