import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {HomeSession} from '../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../services/commandService'
import {Device} from '../../models/device'
import {Playlist} from '../../models/playlist'
import {AngularFirestore} from '@angular/fire/firestore'
import moment from 'moment'


@Component({
    selector:'currentPlaylist',
    templateUrl:'currentPlaylist.html'
})
export class CurrentPlaylist implements OnInit, OnDestroy {

    subscription:Subscription = null
    deviceSubscription:Subscription = null
    showsSubscription:Subscription = null
    device:Device
    playlist:Playlist = null
    favorites:Playlist = null

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        public commandService:CommandService,
        public fs:AngularFirestore,
        public zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.updateInfo()
        this.favorites = this.homeSession.favorites
        this.deviceSubscription = this.device.getObservable().subscribe(value => {
            console.log('Playlist got values ' + value)
            if ((this.playlist == null) || (this.device.playlist != this.playlist.id))
                this.updateInfo()
        })
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe()
        if (this.deviceSubscription)
            this.deviceSubscription.unsubscribe()
    }

    async updateInfo() {
        if (this.subscription)
            this.subscription.unsubscribe()

        this.playlist = new Playlist()
        if (this.device.autoIndex == 0) {
            this.device._playlistName = ' Colors'
            return
        }

        if ((!this.device.playlist) || (this.device.playlist == '0000')) {
            return
        }

        this.subscription = this.fs.doc('playlists/' + this.device.playlist).valueChanges().subscribe(value => {
            this.playlist = new Playlist(value as object)
            this.device._playlistName = this.playlist.playlist
        })
    }

    async playShow(index){
        let command = 'showIndex/' + index
        console.log(command + ' for ' + this.device.device)
        this.device.showIndex = index
        this.commandService.publish(this.device, command)
    }

    async toggleFavorites(show) {
        let index = this.favorites.shows.indexOf(show)
        if (index == -1) {
              this.favorites.shows.push(show)
        } else {
            this.favorites.shows.splice(index, 1)
        }
        await this.fs.doc('personal/' + this.favorites.id).update({'shows': this.favorites.shows, 'epoch': moment().unix()})
        this.commandService.publishToConnectedDevices('getIgnore')
    }
}

