import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router';
import {HomeSession} from '../../services/homeSession';
import {Insomnia} from '@ionic-native/insomnia/ngx'
import {Platform} from '@ionic/angular'

let logToServer = function (consoleMsg) {

    let jsonTxt = consoleMsg
    var xmlHttp = new XMLHttpRequest()
    xmlHttp.open("GET", 'http://10.0.1.142:7000/log?msg=' + jsonTxt, true) //async
    xmlHttp.send(null)
}

@Component({
    selector:'page-login',
    templateUrl:'login.html',
})
export class LoginPage implements OnInit {

    initializing = true
    showLogin:boolean = false

    constructor(public router:Router,
                protected insomnia:Insomnia,
                protected homeSession:HomeSession,
                public plt:Platform) {
    }

    ngOnInit() {
        this.plt.ready().then((readySource) => {
            if (readySource == 'cordova') {
                this.homeSession.onMobile = true
            }
        })
    }

    googleLoggedIn(value:string) {
        console.log('google login event', value)
        this.initializing = false
        if (value == 'login') {
            this.showLogin = true
            return
        }

        if ((this.homeSession.user.email == 'yiotix@gmail.com') || (this.homeSession.user.email == 'yiotis@emolights.com')) {
            this.homeSession.isDev = true
            if (this.homeSession.onMobile) {
                // intercept console logs
                console.log = function (message) {
                    logToServer(message)
                }
                console.error = function (errorObject, errorMessage) {
                    logToServer('Error ' + errorMessage.message)
                }
                this.insomnia.keepAwake()
            }
        }


        let route = ''
        if (this.homeSession.firstRoute != '')
            route = this.homeSession.firstRoute
        else route = value

        this.homeSession.firstRoute = ''
        this.router.navigateByUrl(route)

    }
}
