import {Component} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {Subscription} from 'rxjs'
import {Device} from '../../models/device'


@Component({
    selector:'modalHeader',
    templateUrl:'modalHeader.html'
})
export class ModalHeader{
    subscription:Subscription = null
    device:Device

    constructor(
        public modalController: ModalController) {
    }

    closeModal() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}

