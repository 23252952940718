import {AfterViewInit, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ModalController, Platform} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {interval, Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {Insomnia} from '@ionic-native/insomnia/ngx'


/**
 * DSP is an object which contains general purpose utility functions and constants
 */
let DSP = {
    // Channels
    LEFT:0,
    RIGHT:1,
    MIX:2,

    // Waveforms
    SINE:1,
    TRIANGLE:2,
    SAW:3,
    SQUARE:4,

    // Filters
    LOWPASS:0,
    HIGHPASS:1,
    BANDPASS:2,
    NOTCH:3,
    LP12:4,

    // Window functions
    BARTLETT:1,
    BARTLETTHANN:2,
    BLACKMAN:3,
    COSINE:4,
    GAUSS:5,
    HAMMING:6,
    HANN:7,
    LANCZOS:8,
    RECTANGULAR:9,
    TRIANGULAR:10,

    // Loop modes
    OFF:0,
    FW:1,
    BW:2,
    FWBW:3,

    // Math
    TWO_PI:2 * Math.PI
}


class IIRFilter {

    func = null
    sampleRate = null

    constructor(type, cutoff, resonance, sampleRate) {
        this.sampleRate = sampleRate

        switch (type) {
            case DSP.LOWPASS:
            case DSP.LP12:
                this.func = new LP12(cutoff, resonance, sampleRate)
                break
        }
    }

    get cuttoff2() {
        return this.func.cutoff
    }


    get resonance2() {
        return this.func.resonance
    }


    resonancecutoff(cutoff, resonance) {
        this.func.calcCoeff(cutoff, resonance)
    }


    process(buffer) {
        this.func.process(buffer)
    }

// Add an envelope to the filter
    addEnvelope(envelope) {
        if (envelope instanceof ADSR) {
            this.func.addEnvelope(envelope)
        } else {
            throw "Not an envelope."
        }
    }
}

class LP12 extends IIRFilter {

    vibraPos = null
    vibraSpeed = null
    envelope = null
    w = null
    q = null
    r = null
    c = null
    cutoff = null
    resonance = null

    constructor(cutoff, resonance, sampleRate) {
        super('', cutoff, resonance, sampleRate)

        this.sampleRate = sampleRate
        this.vibraPos = 0
        this.vibraSpeed = 0
        this.envelope = false
        this.calcCoeff(cutoff, resonance)
    }

    calcCoeff(cutoff, resonance) {
        this.w = 2.0 * Math.PI * cutoff / this.sampleRate
        this.q = 1.0 - this.w / (2.0 * (resonance + 0.5 / (1.0 + this.w)) + this.w - 2.0)
        this.r = this.q * this.q
        this.c = this.r + 1.0 - 2.0 * Math.cos(this.w) * this.q

        this.cutoff = cutoff
        this.resonance = resonance
    }


    process(buffer) {
        for (var i = 0; i < buffer.length; i++) {
            this.vibraSpeed += (buffer[i] - this.vibraPos) * this.c
            this.vibraPos += this.vibraSpeed
            this.vibraSpeed *= this.r

            /*
            var temp = this.vibraPos;

            if ( temp > 1.0 ) {
              temp = 1.0;
            } else if ( temp < -1.0 ) {
              temp = -1.0;
            } else if ( temp != temp ) {
              temp = 1;
            }

            buffer[i] = temp;
            */

            if (this.envelope) {
                buffer[i] = (buffer[i] * (1 - this.envelope.value())) + (this.vibraPos * this.envelope.value())
                this.envelope.samplesProcessed++
            } else {
                buffer[i] = this.vibraPos
            }
        }
    }

    addEnvelope(envelope) {
        this.envelope = envelope
    }
}

class ADSR {
    sampleRate = null
    attackLength = null
    decayLength = null
    sustainLevel = null
    sustainLength = null
    decaySamples = null
    attackSamples = null
    sustainSamples = null
    releaseSamples = null
    samplesProcessed = null
    releaseLength = null
    attack = null
    decay = null
    sustain = null
    release = null

    constructor(attackLength, decayLength, sustainLevel, sustainLength, releaseLength, sampleRate) {
        this.sampleRate = sampleRate
        // Length in seconds
        this.attackLength = attackLength
        this.decayLength = decayLength
        this.sustainLevel = sustainLevel
        this.sustainLength = sustainLength
        this.releaseLength = releaseLength
        this.sampleRate = sampleRate

        // Length in samples
        this.attackSamples = attackLength * sampleRate
        this.decaySamples = decayLength * sampleRate
        this.sustainSamples = sustainLength * sampleRate
        this.releaseSamples = releaseLength * sampleRate


        this.update()

        this.samplesProcessed = 0
    }

    // Updates the envelope sample positions
    update() {
        this.attack = this.attackSamples
        this.decay = this.attack + this.decaySamples
        this.sustain = this.decay + this.sustainSamples
        this.release = this.sustain + this.releaseSamples
    }

    noteOn() {
        this.samplesProcessed = 0
        this.sustainSamples = this.sustainLength * this.sampleRate
        this.update()
    };

// Send a note off when using a sustain of infinity to let the envelope enter the release phase
    noteOff() {
        this.sustainSamples = this.samplesProcessed - this.decaySamples
        this.update()
    };

    processSample(sample) {
        var amplitude = 0

        if (this.samplesProcessed <= this.attack) {
            amplitude = 0 + (1 - 0) * ((this.samplesProcessed - 0) / (this.attack - 0))
        } else if (this.samplesProcessed > this.attack && this.samplesProcessed <= this.decay) {
            amplitude = 1 + (this.sustainLevel - 1) * ((this.samplesProcessed - this.attack) / (this.decay - this.attack))
        } else if (this.samplesProcessed > this.decay && this.samplesProcessed <= this.sustain) {
            amplitude = this.sustainLevel
        } else if (this.samplesProcessed > this.sustain && this.samplesProcessed <= this.release) {
            amplitude = this.sustainLevel + (0 - this.sustainLevel) * ((this.samplesProcessed - this.sustain) / (this.release - this.sustain))
        }

        return sample * amplitude
    };

    value() {
        var amplitude = 0

        if (this.samplesProcessed <= this.attack) {
            amplitude = 0 + (1 - 0) * ((this.samplesProcessed - 0) / (this.attack - 0))
        } else if (this.samplesProcessed > this.attack && this.samplesProcessed <= this.decay) {
            amplitude = 1 + (this.sustainLevel - 1) * ((this.samplesProcessed - this.attack) / (this.decay - this.attack))
        } else if (this.samplesProcessed > this.decay && this.samplesProcessed <= this.sustain) {
            amplitude = this.sustainLevel
        } else if (this.samplesProcessed > this.sustain && this.samplesProcessed <= this.release) {
            amplitude = this.sustainLevel + (0 - this.sustainLevel) * ((this.samplesProcessed - this.sustain) / (this.release - this.sustain))
        }

        return amplitude
    };

    process(buffer) {
        for (var i = 0; i < buffer.length; i++) {
            buffer[i] *= this.value()

            this.samplesProcessed++
        }

        return buffer
    };


    isActive() {
        if (this.samplesProcessed > this.release || this.samplesProcessed === -1) {
            return false
        } else {
            return true
        }
    };

    disable() {
        this.samplesProcessed = -1
    };
}

// Fourier Transform Module used by DFT, FFT, RFFT
class FourierTransform {
    bufferSize = 0
    sampleRate = 0
    bandwidth = 0

    spectrum = new Float64Array(0)
    real = new Float64Array(0)
    imag = new Float64Array(0)

    peakBand = 0
    peak = 0

    constructor(bufferSize, sampleRate) {

        this.bufferSize = bufferSize
        this.sampleRate = sampleRate
        this.bandwidth = 2 / bufferSize * sampleRate / 2

        this.spectrum = new Float64Array(bufferSize / 2)
        this.real = new Float64Array(bufferSize)
        this.imag = new Float64Array(bufferSize)

        this.peakBand = 0
        this.peak = 0
    }

    /**
     * Calculates the *middle* frequency of an FFT band.
     *
     * @param {Number} index The index of the FFT band.
     *
     * @returns The middle frequency in Hz.
     */
    getBandFrequency(index) {
        return this.bandwidth * index + this.bandwidth / 2
    }

    calculateSpectrum() {
        var spectrum = this.spectrum,
            real = this.real,
            imag = this.imag,
            bSi = 2 / this.bufferSize,
            sqrt = Math.sqrt,
            rval,
            ival,
            mag

        for (var i = 0, N = this.bufferSize / 2; i < N; i++) {
            rval = real[i]
            ival = imag[i]
            mag = bSi * sqrt(rval * rval + ival * ival)

            if (mag > this.peak) {
                this.peakBand = i
                this.peak = mag
            }

            spectrum[i] = mag
        }
    }
}


/**
 * FFT is a class for calculating the Discrete Fourier Transform of a signal
 * with the Fast Fourier Transform algorithm.
 *
 * @param {Number} bufferSize The size of the sample buffer to be computed. Must be power of 2
 * @param {Number} sampleRate The sampleRate of the buffer (eg. 44100)
 *
 * @constructor
 */
export class FFT extends FourierTransform {

    reverseTable = null
    sinTable = null
    cosTable = null

    constructor(bufferSize, sampleRate) {
        super(bufferSize, sampleRate)

        this.reverseTable = new Uint32Array(bufferSize)

        var limit = 1
        var bit = bufferSize >> 1

        var i

        while (limit < bufferSize) {
            for (i = 0; i < limit; i++) {
                this.reverseTable[i + limit] = this.reverseTable[i] + bit
            }

            limit = limit << 1
            bit = bit >> 1
        }

        this.sinTable = new Float64Array(bufferSize)
        this.cosTable = new Float64Array(bufferSize)

        for (i = 0; i < bufferSize; i++) {
            this.sinTable[i] = Math.sin(-Math.PI / i)
            this.cosTable[i] = Math.cos(-Math.PI / i)
        }
    }

    /**
     * Performs a forward transform on the sample buffer.
     * Converts a time domain signal to frequency domain spectra.
     *
     * @param {Array} buffer The sample buffer. Buffer Length must be power of 2
     *
     * @returns The frequency spectrum array
     */
    forward(buffer) {
        // Locally scope variables for speed up
        var bufferSize = this.bufferSize,
            cosTable = this.cosTable,
            sinTable = this.sinTable,
            reverseTable = this.reverseTable,
            real = this.real,
            imag = this.imag,
            spectrum = this.spectrum

        var k = Math.floor(Math.log(bufferSize) / Math.LN2)

        if (Math.pow(2, k) !== bufferSize) {
            throw "Invalid buffer size, must be a power of 2."
        }
        if (bufferSize !== buffer.length) {
            throw "Supplied buffer is not the same size as defined FFT. FFT Size: " + bufferSize + " Buffer Size: " + buffer.length
        }

        var halfSize = 1,
            phaseShiftStepReal,
            phaseShiftStepImag,
            currentPhaseShiftReal,
            currentPhaseShiftImag,
            off,
            tr,
            ti,
            tmpReal,
            i

        for (i = 0; i < bufferSize; i++) {
            real[i] = buffer[reverseTable[i]]
            imag[i] = 0
        }

        while (halfSize < bufferSize) {
            //phaseShiftStepReal = Math.cos(-Math.PI/halfSize);
            //phaseShiftStepImag = Math.sin(-Math.PI/halfSize);
            phaseShiftStepReal = cosTable[halfSize]
            phaseShiftStepImag = sinTable[halfSize]

            currentPhaseShiftReal = 1
            currentPhaseShiftImag = 0

            for (var fftStep = 0; fftStep < halfSize; fftStep++) {
                i = fftStep

                while (i < bufferSize) {
                    off = i + halfSize
                    tr = (currentPhaseShiftReal * real[off]) - (currentPhaseShiftImag * imag[off])
                    ti = (currentPhaseShiftReal * imag[off]) + (currentPhaseShiftImag * real[off])

                    real[off] = real[i] - tr
                    imag[off] = imag[i] - ti
                    real[i] += tr
                    imag[i] += ti

                    i += halfSize << 1
                }

                tmpReal = currentPhaseShiftReal
                currentPhaseShiftReal = (tmpReal * phaseShiftStepReal) - (currentPhaseShiftImag * phaseShiftStepImag)
                currentPhaseShiftImag = (tmpReal * phaseShiftStepImag) + (currentPhaseShiftImag * phaseShiftStepReal)
            }

            halfSize = halfSize << 1
        }

        return this.calculateSpectrum()
    }

    inverse(real, imag) {
        // Locally scope variables for speed up
        var bufferSize = this.bufferSize,
            cosTable = this.cosTable,
            sinTable = this.sinTable,
            reverseTable = this.reverseTable,
            spectrum = this.spectrum

        real = real || this.real
        imag = imag || this.imag

        var halfSize = 1,
            phaseShiftStepReal,
            phaseShiftStepImag,
            currentPhaseShiftReal,
            currentPhaseShiftImag,
            off,
            tr,
            ti,
            tmpReal,
            i

        for (i = 0; i < bufferSize; i++) {
            imag[i] *= -1
        }

        var revReal = new Float64Array(bufferSize)
        var revImag = new Float64Array(bufferSize)

        for (i = 0; i < real.length; i++) {
            revReal[i] = real[reverseTable[i]]
            revImag[i] = imag[reverseTable[i]]
        }

        real = revReal
        imag = revImag

        while (halfSize < bufferSize) {
            phaseShiftStepReal = cosTable[halfSize]
            phaseShiftStepImag = sinTable[halfSize]
            currentPhaseShiftReal = 1
            currentPhaseShiftImag = 0

            for (var fftStep = 0; fftStep < halfSize; fftStep++) {
                i = fftStep

                while (i < bufferSize) {
                    off = i + halfSize
                    tr = (currentPhaseShiftReal * real[off]) - (currentPhaseShiftImag * imag[off])
                    ti = (currentPhaseShiftReal * imag[off]) + (currentPhaseShiftImag * real[off])

                    real[off] = real[i] - tr
                    imag[off] = imag[i] - ti
                    real[i] += tr
                    imag[i] += ti

                    i += halfSize << 1
                }

                tmpReal = currentPhaseShiftReal
                currentPhaseShiftReal = (tmpReal * phaseShiftStepReal) - (currentPhaseShiftImag * phaseShiftStepImag)
                currentPhaseShiftImag = (tmpReal * phaseShiftStepImag) + (currentPhaseShiftImag * phaseShiftStepReal)
            }

            halfSize = halfSize << 1
        }

        var buffer = new Float64Array(bufferSize) // this should be reused instead
        for (i = 0; i < bufferSize; i++) {
            buffer[i] = real[i] / bufferSize
        }

        return buffer
    }
}

declare let audioinput:any


@Component({
    selector:'deviceToMusic',
    templateUrl:'deviceToMusic.html'
})
export class DeviceToMusic implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('canvas')
    canvas:ElementRef<HTMLCanvasElement>

    device:Device

    background:string = '#210610'
    microphone = 80
    ctx:CanvasRenderingContext2D
    currentValues:number[] = []
    subscription:Subscription
    sending:boolean = false
    color:number[] = [25, 25, 25]
    offset:number = 0
    recentAmps = []
    resWidth = 300
    listening:boolean
    message:string
    sleepSubscription = null
    bufferSize = 4096
    sampleBufferSize = this.bufferSize / 2

    last1 = 0
    last2 = 0
    last3 = 0
    private send:boolean = false
    private resHeight:number

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        protected commandService:CommandService,
        public modalController:ModalController,
        protected zone:NgZone,
        public platform:Platform,
        protected insomnia:Insomnia) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
    }

    ngOnDestroy() {
    }

    closeModal() {
        this.modalController.dismiss({
            'dismissed':true
        })
    }

    ngAfterViewInit() {
        this.sending = false
        this.ctx = this.canvas.nativeElement.getContext('2d')

        this.platform.ready().then((readySource) => {

            console.log('initializing ' + readySource)

            if (this.platform.is('android'))
                this.sampleBufferSize = this.bufferSize

            if (readySource == 'cordova') {
                this.audioCheck()
                this.resWidth = this.canvas.nativeElement.width
                this.resHeight = this.canvas.nativeElement.height
                this.ctx.clearRect(0, 0, this.resWidth, this.resHeight)
                console.log('Res Width', this.resWidth)
            } else {
                console.log('Not a device')
            }
        })

    }

    @HostListener('window:audioinput', ['$event'])
    onAudioInput(evt) {
        let length = evt.data.length

        try {
            let buffer:any[] = []
            for (let x = 0; x < length; x++) {
                buffer.push(evt.data[x])
            }

            if (buffer.length / 2 != Math.floor(buffer.length))
                buffer.push(buffer[0])

            try {
                var dft = new FFT(this.sampleBufferSize, 22050)

                var filter = new IIRFilter(DSP.LOWPASS, 200, 1, 22050)
                filter.process(buffer)

                dft.forward(buffer)
            } catch (e) {
                console.log(e)
                return
            }

            let spectrum = dft.spectrum
            let blockRange = Math.round(spectrum.length / 3)

            let y = 0
            let block = 0
            let nextBlock = blockRange
            let blocks = [0]

            for (let x = 0; x < spectrum.length; x++) {
                if (x > nextBlock) {
                    block += 1
                    nextBlock = (block + 1) * blockRange
                    blocks.push(0)
                }

                y = spectrum[x] * (1000 * x)
                blocks[block] += y
            }

            this.offset += 1
            let average = 0

            let e1 = 0
            let e2 = 0
            let e3 = 0

            let c1 = Math.max(0, Math.round((blocks[0] / blockRange) / this.sensitive) - 10)
            let c2 = Math.max(0, Math.round((blocks[1] / blockRange) / this.sensitive) - 10)
            let c3 = Math.max(0, Math.round((blocks[2] / blockRange) / this.sensitive) - 10)

            // console.log('blocks ' + Math.round((blocks[0] / blockRange) / this.multiplier)  + ' ' + Math.round((blocks[1] / blockRange) / this.multiplier)  + ' ' + Math.round((blocks[2] / blockRange) / this.multiplier))
            if (c1 > this.last1 * 2)
                e1 = c1
            if (c2 > this.last2 * 2)
                e2 = c2
            if (c3 > this.last3 * 2)
                e3 = c3

            this.last1 = c1
            this.last2 = c2
            this.last3 = c3

            average = c1 + c2 + c3
            this.recentAmps.push(average)
            if (this.recentAmps.length > 50)
                this.recentAmps.splice(0, this.recentAmps.length - 50)

            for (let x = 0; x < 3; x++) {
                let a = ((blocks[x] / blockRange) / this.sensitive) - 10

                for (let p = 0; p < 50; p++) {

                    let pi = (x * 50) + p
                    pi = ((pi + this.offset) % 150)

                    if (!this.currentValues[pi])
                        this.currentValues[pi] = 0

                    let b = p

                    if (b <= 25)
                        b = 25 - b
                    else
                        b = p - 25

                    if (a > b)
                        this.currentValues[pi] = 9
                    else
                        this.currentValues[pi] = Math.max(0, Math.floor(this.currentValues[pi] - ((b + 10) * .11)))

                    average += this.currentValues[pi]
                }
            }

            let blockWidth = this.resWidth / 150
            for (let x = 0; x < 150; x++) {
                let y = this.currentValues[x]
                this.ctx.fillStyle = 'rgb(' + (y * this.color[0]) + ',' + (y * this.color[1]) + ',' + (y * this.color[2]) + ')'
                this.ctx.fillRect(x * 2, 0, blockWidth, this.resHeight)
            }

            this.send = true
            // if (e1 + e2 + e3 > 0) {
            //     this.send = true
            // }

        } catch (e) {
            console.log('error', e)
        }
    }

    get sensitive() {
        return (101 - this.microphone) / 100
    }

    async startCapture() {
        //Subscribe on pause i.e. background
        this.sleepSubscription = this.platform.pause.subscribe(() => {
            this.stopCapture()
        })
        this.listening = true
        this.message = 'Listening to Music'
        await this.homeSession.sleep(100)
        if (this.homeSession.onMobile)
            await this.insomnia.keepAwake()

        audioinput.start({sampleRate:audioinput.SAMPLERATE.CD_HALF_22050Hz, bufferSize:this.bufferSize})
        this.subscription = interval(10).subscribe(() => {
            this.sendSpectrum()
        })
    }

    async sendSpectrum() {
        if (this.send == false)
            return

        this.send = false
        this.color = [15, 5, 25]

        try {
            let msg = 'soundAmp/' + this.currentValues.join(',')
            msg = msg.replace(/,/g, '')
            this.commandService.publish(this.device, msg, true)
        } catch (e) {
            console.log("Error", e)
        }
    }

    async stopCapture() {
        this.subscription.unsubscribe()
        this.message = ''
        audioinput.stop()
        this.ctx.clearRect(0, 0, this.resWidth, this.resHeight)
        this.listening = false
        this.insomnia.allowSleepAgain()
        this.subscription.unsubscribe()


        for (let device of this.homeSession.devices) {
            device._busy = false
        }
        await this.homeSession.sleep(1000)

        let msg = 'soundAmp/1'
        this.commandService.publish(this.device, msg)

        for (let device of this.homeSession.devices) {
            device._busy = false
        }

        await this.homeSession.sleep(1000)

        msg = 'restartAnim/1'
        this.commandService.publish(this.device, msg)
    }

    audioCheck() {
        audioinput.checkMicrophonePermission(hasPermission => {
            if (hasPermission) {
                console.log("We already have permission to record.")
            } else {
                // Ask the user for permission to access the microphone
                audioinput.getMicrophonePermission((hasPermission, message) => {
                    if (hasPermission) {
                        console.log("User granted us permission to record.")
                        this.startCapture()
                    } else {
                        this.message = 'Can not access microphone. Check the emolight app settings.'
                        console.log("User denied permission to record.")
                    }
                })
            }
        })
    }

    goBack() {
        this.stopCapture()
        this.router.navigateByUrl('/schedules')
    }
}

