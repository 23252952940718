import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {Days, ActionLabels, Device} from '../../../models/device'
import {HomeSession} from '../../../services/homeSession'
import {CommandService} from '../../../services/commandService'
import moment from 'moment'
import {ChoosePlaylist} from './choosePlaylist'
import {ChooseBrightness} from './chooseBrightness'
import {ChooseColors} from './chooseColors'


@Component({
    selector:'deviceTriggerConfigAction',
    templateUrl:'deviceTriggerConfigAction.html'
})
export class DeviceTriggerConfigAction implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    schedule:any
    originalSchedule:any
    datetime = moment()

    actions:string[] = ['powerOn',
        'powerOff',
        'brightness',
        'colors',
        'playlist']

    actionLabels = ActionLabels
    dayLabels = Days
    trigger = {action:'', days:['1', '2', '3', '4', '5', '6', '7']}

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.trigger = this.homeSession.selectedTrigger
        this.schedule = this.trigger['time']
        this.originalSchedule = this.schedule

        if (Number(this.schedule) > -1) {
            let hour:number = Math.floor(this.schedule / 60)
            let minutes:number = this.schedule - (hour * 60)
            this.datetime.set('hour', hour)
            this.datetime.set('minutes', minutes)
        }
    }

    ngOnDestroy() {
    }

    async closeModal(data = '', sleep = 0) {
        await this.homeSession.sleep(sleep)
        await this.store()
        this.homeSession.buildProgram(this.device)
        await this.modalController.dismiss('closeAll')
    }

    async store() {
        if (this.device.triggers.indexOf(this.trigger) == -1) {
            if (this.trigger['action'])
                this.device.triggers.push(this.trigger)
            else
                return
        }
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setPower(power) {
        this.trigger['action'] = 'power'
        this.trigger['values'] = {power: power}
        this.store()
    }

    async chooseColors() {
        if (this.trigger['action'] != 'colors') {
            this.trigger['action'] = 'colors'
            this.trigger['enabled'] = true
            this.trigger['values'] =  {}
            this.trigger['values']['seasons'] = true
            this.trigger['values']['colors'] = [0xff00ff]
            this.trigger['values']['animate'] = true
            this.trigger['values']['fade'] = true
            this.trigger['values']['fadeSpeed'] = 100
            this.trigger['values']['animation'] = true
            this.trigger['values']['animationSpeed'] = 100
            this.trigger['values']['duration'] = 100
        }

        const modal = await this.modalController.create({
            component:ChooseColors,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.closeModal('closeAll', 10)
        })

        return await modal.present()
    }

    async choosePlaylist() {
        if (this.trigger['action'] != 'playlist') {
            this.trigger['action'] = 'playlist'
            this.trigger['enabled'] = true
            this.trigger['values'] =  {}
            this.trigger['values']['seasons'] = true
            this.trigger['values']['playlist'] = 1
        }

        const modal = await this.modalController.create({
            component:ChoosePlaylist,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.closeModal('closeAll', 10)
        })

        await modal.present()
    }

    async chooseBrightness() {
        if (this.trigger['action'] != 'brightness') {
            this.trigger['action'] = 'brightness'
            this.trigger['values'] = {}
            this.trigger['values']['brightness'] = 100
        }

        const modal = await this.modalController.create({
            component:ChooseBrightness,
            animated:true,
            cssClass: "modal-fullscreen"
        })

        modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.closeModal('closeAll', 10)
        })

        return await modal.present()
    }
}

