import {Component, Input} from '@angular/core'
import {ProfilePage} from '../../pages/profile/profile'
import {DevicesPage} from '../../pages/devices/devices'
import {HomeSession} from '../../services/homeSession'
import {Router} from '@angular/router'


@Component({
    selector:'menu-options',
    templateUrl:'menu-options.html'
})
export class MenuOptionsComponent {

    @Input()
    public text:string = ''

    profile = ProfilePage
    lights = DevicesPage

    constructor(private router:Router,
                public homeSesion:HomeSession) {
    }

    navigateToPage(page) {
        this.router.navigateByUrl(page)
    }
}
