import {Component, NgZone, OnDestroy, OnInit, ViewChildren} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {DeviceName} from './deviceName'
import {HttpClient} from '@angular/common/http'
import {BleService} from '../../../services/bleService'


@Component({
    selector:'deviceWiFiPassword',
    templateUrl:'deviceWiFiPassword.html'
})
export class DeviceWiFiPassword implements OnInit, OnDestroy {
    @ViewChildren('input')
    passwordInput

    subscription:Subscription = null
    device:Device
    password:any
    passwordVisible:boolean = true
    focusNeeded:boolean = true
    passwordLegacySet = false
    saving:boolean = false

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        private http:HttpClient,
        private bleService:BleService,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice

        if (this.device._new)
            this.commandService.publish(this.device, "reset", false)

        this.subscription = this.device.getObservable().subscribe(message => {
            this.processIncoming(message)
        })
    }


    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe()
    }

    async closeModal(data = '', sleep = 0) {
        await this.homeSession.sleep(sleep)
        await this.modalController.dismiss(data)
    }


    async processIncoming(message:string) {
        console.log('ble msg:' + message)

        if (message == 'wfap') {
            await this.commandService.publish(this.device, 'setWifiPS/' + this.password, false)
        }

        // reset the device
        if (message == 'wfps') {
            if (this.device._new) {
                await this.commandService.publish(this.device, 'forceUpdate', false)
                await this.updateDevice()
            } else {
                console.log('dev device ' + this.device.device)
                console.log('dev homeid ' + this.device.homeId)
                console.log('dev timezone ' + this.device.timezone)
                this.closeModal('closeAll')
            }
        }

        if (this.passwordLegacySet)
            if (this.device._new) {
                await this.updateDevice()
            }
    }

    async savePassword() {
        if (this.saving)
            return

        this.saving = true

        if (this.device._new)
            await this.homeSession.sleep(5000)

        if (String(this.device.appVersion).indexOf('.') > -1) {
            this.commandService.publish(this.device, 'setWifiAP/' + this.homeSession.selectedAP, false)
        } else {
            // LEGACY
            this.commandService.publish(this.device, '{"command":"setWifi", "ap": "' + this.homeSession.selectedAP +
                '", "ps": "' + this.password + '"}',
                false)

            this.passwordLegacySet = true
        }
    }

    setFocus(input) {
        console.log('setting focus')
        this.focusNeeded = false
        setTimeout(() => {
            input.setFocus()
        }, 1000)
    }

    showPassword(input:any):any {
        input.type = input.type === 'password' ? 'text' : 'password'
        this.passwordVisible = input.type != 'password'
        this.setFocus(input)
    }

    async openNameModal() {
        const modal = await this.modalController.create({
            component:DeviceName,
            animated:true
        })

        modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.closeModal('closeAll', 10)
        })

        return await modal.present()
    }

    async updateDevice() {
        this.device.device = 'My Emolights'

        if (this.homeSession.devices.indexOf(this.device) == -1)
            this.homeSession.devices.push(this.device)

        if (this.bleService.newDevices.indexOf(this.device) > -1)
            this.bleService.newDevices.splice(this.bleService.newDevices.indexOf(this.device), 1)

        await this.homeSession.updateDevice(this.device, this.device.getDict())
        this.openNameModal()
    }
}

