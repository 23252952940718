import {Component, NgZone, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import moment from 'moment'
import {Observable} from 'rxjs/Observable'
import {AngularFirestore} from '@angular/fire/firestore'
import {DeviceSeasonConfig} from './deviceSeasonConfig'

@Pipe({
    name:'orderSeasons'
})
export class OrderSeasons implements PipeTransform {
    transform(values:any[], key?:string, reverse?:boolean) {
        console.log('sorting')
        if (values)
            values.sort(this.compareSeasons)
        return values
    }

    private compareSeasons(a, b) {
        let aDate = moment()
        aDate.set('month', a.startMonth - 1)
        aDate.set('date', a.startDay)

        let bDate = moment()
        bDate.set('month', b.startMonth - 1)
        bDate.set('date', b.startDay)

        if (aDate == bDate)
            return 0
        if (aDate < bDate)
            return -1

        return 1
    }
}


@Component({
    selector:'deviceSeasons',
    templateUrl:'deviceSeasons.html'
})
export class DeviceSeasons implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    seasons:any[] = []
    seasonsCollection:Observable<any>

    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.getSeasons()
        this.device = this.homeSession.selectedDevice
        console.log('Connected device ' + this.homeSession.selectedDevice.device)
    }

    ngOnDestroy() {
    }

    getSeasons() {
        this.seasonsCollection = this.homeSession.seasonsCollection
        this.seasonsCollection.subscribe(
            result => {
                console.log('got seasons ')
                result.forEach(season => {
                        // Updating overwrites for our devices
                        if (this.device.seasons[season['index']] == undefined)
                            this.device.seasons[season['index']] = true
                    }
                )
            })
    }

    closeModal() {
        this.modalController.dismiss('closeAll')
    }

    getDates(season:any) {

        let item = season
        if (this.device.seasonDates[season.index]) {
            item = this.device.seasonDates[season.index]
        }

        let result = item.startMonth + '/' + item.startDay

        if ((item.startDay == item.endDay) && (item.startMonth == item.endMonth)) {
        } else {
            result += ' - '
            result += item.endMonth + '/' + item.endDay
        }

        return result
    }

    async storeHomeSession() {
        await this.homeSession.updateDevice(this.device, {'seasons':this.device.seasons})
        this.homeSession.buildProgram(this.device)
    }

    async configSeason(season) {
        this.homeSession.selectedSeason = season
        const modal = await this.modalController.create({
            component:DeviceSeasonConfig,
            animated:true
        })
        return await modal.present()
    }
}

