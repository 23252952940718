import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'


@Component({
    selector:'deviceName',
    templateUrl:'deviceName.html'
})
export class DeviceName implements OnInit{
    subscription:Subscription = null
    device:Device
    name:string = ''
    focusNeeded:boolean = true

    names:string[] = [
        'Xmas Tree',
        'Out Front',
        'Out Back',
        'Living Room',
        'Bedroom',
        'Office',
        'Bookcase',
        'Fireplace',
        'Window']

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController: ModalController,
        private zone: NgZone) {
    }

    ngOnInit() {
        this.names.sort()
        this.device = this.homeSession.selectedDevice
        this.name = this.device.device
    }

    async closeModal(data='') {
        console.log('closing with data ' + data)
        await this.modalController.dismiss(data)
    }

    async saveName() {
        this.device.device = this.name
        await this.homeSession.updateDevice(this.device, {'device': this.device.device})
        await this.closeModal('closeAll')
    }

    setName(name:string) {
        this.name = name;
    }

    setFocus(input) {
        console.log('setting focus')
        this.focusNeeded = false
        setTimeout(() => {
            input.setFocus();
        },1000);
    }
}

