import {Component} from '@angular/core'
import {Platform} from '@ionic/angular'
// import {IAPProduct, InAppPurchase2} from '@ionic-native/in-app-purchase-2/ngx'
import {HomeSession} from '../../services/homeSession'
import {Router} from '@angular/router';

/**
 * Generated class for the SubscriptionPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */


@Component({
    selector:'page-subscription',
    templateUrl:'subscription.html',
})
export class SubscriptionPage {

    productId = "emo.subscription.monthlly.01"

    constructor(public plt:Platform,
                public router:Router,
                private homeSession:HomeSession,
                // private store:InAppPurchase2
    ) {
    }

    ionViewWillEnter() {
        console.log('ionViewDidLoad ProfilePage')
        this.plt.ready().then((readySource) => {

            console.log('getting products')
            if (readySource == 'cordova') {
                try {
                    console.log('getting products')
                    this.initStore()
                } catch (e) {
                    console.log('getting products error', e)
                }
            } else {
            }
        })
    }

    goBack() {
        this.router.navigateByUrl('/schedules')
    }

    initStore() {
        // // After platform ready
        // this.store.verbosity = this.store.DEBUG
        // this.store.register({
        //     id:this.productId,
        //     type:this.store.PAID_SUBSCRIPTION,
        // })
        //
        // this.store.validator =
        //     "https://validator.fovea.cc/v1/validate?appName=com.emolights.app2&apiKey=35655189-9a06-4d1d-bb58-5d26e065d7b7"
        //
        // // Register event handlers for the specific product
        // this.store.when(this.productId).registered((product:IAPProduct) => {
        //     console.log('Registered: ')
        // })
        //
        // // Updated
        // this.store.when(this.productId).updated((product:IAPProduct) => {
        //     console.log('Updated')
        // })
        //
        // // User closed the native purchase dialog
        // this.store.when(this.productId).cancelled((product) => {
        //     console.error('Purchase was Cancelled')
        // })
        //
        // // Track all store errors
        // this.store.error((err) => {
        //     console.error('Store Error ', err)
        // })
        //
        // // Run some code only when the store is ready to be used
        // this.store.ready(() => {
        //     console.log('Store is ready')
        //     console.log('Products: ', this.store.products)
        //     console.log(this.store.get(this.productId))
        // })
    }


    refreshStatus() {
        // // Refresh the status of in-app products
        // this.store.refresh()
    }

    order() {
        // To make a purchase
        // this.store.order(this.productId).then(
        //     () => {
        //         console.log('ordered')
        //     },
        //     () => {
        //         console.log('errored')
        //     })
    }

}
