import {Component, NgZone, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import moment from 'moment'
import {Observable} from 'rxjs/Observable'
import {AngularFirestore} from '@angular/fire/firestore'

@Pipe({
    name:'orderPlaylists'
})
export class OrderPlaylists implements PipeTransform {
    transform(values:any[], key?:string, reverse?:boolean) {
        console.log('sorting')
        if (values)
            values.sort(this.compareSeasons)
        return values
    }

    private compareSeasons(a, b) {
        let aDate = moment()
        aDate.set('month', a.startMonth - 1)
        aDate.set('date', a.startDay)

        let bDate = moment()
        bDate.set('month', b.startMonth - 1)
        bDate.set('date', b.startDay)

        if (aDate == bDate)
            return 0
        if (aDate < bDate)
            return -1

        return 1
    }
}


@Component({
    selector:'devicePlaylists',
    templateUrl:'devicePlaylists.html'
})
export class DevicePlaylists implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    playlists:Observable<any[]>
    demoIndices = {}

    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController) {
    }

    ngOnInit() {
        this.getPlaylists()
        this.device = this.homeSession.selectedDevice
        console.log('Connected device ' + this.homeSession.selectedDevice.device)
    }

    ngOnDestroy() {
    }


    getPlaylists() {
        this.playlists = this.homeSession.playlists
    }

    playDemo(item:any) {
        if (this.device.updating)
            return
        let command = 'demo/' + item.index
        console.log(command + ' for ' + this.device.device)
        this.commandService.publish(this.device, command)
        this.device.playlist = item.id
        this.device._rgbColors = []
        this.device.updating = true
        this.device.showIndex = 0
    }

    closeModal() {
        this.modalController.dismiss({
            'dismissed':true
        })
    }
}

