/**
 *  2018 Copyright emo lights.
 */


export class Model {
    _name = 'model'
    _data = null
    static _structures = {}

    constructor(data:object = null) {
    }

    initData():void {
        let name = this._name
        let existing = Model._structures[name]
        if (existing == undefined) {
            existing = {}
            let properties = Object.getOwnPropertyNames(this)
            for (let property of properties) {
                if (property.substring(0, 1) == '_')
                    continue
                existing[property] = this[property]
            }
            Model._structures[name] = existing
            console.log('initialized structure for', name)
        }
    }

    public getDict():object {
        return this._data
    }

    public setDict(sourceDict:object = null):void {
        if (sourceDict == null)
            sourceDict = {}

        console.log('assigning dict', sourceDict)

        let defaultDict = Model._structures[this._name]
        let properties = Object.getOwnPropertyNames(defaultDict)
        this._data = sourceDict

        if (this._data == null) {
            this._data = Object.assign({}, defaultDict)
        }

        for (let key of properties) {
            if (key.substring(0, 1) == '_') {
                continue
            }
            if (this._data[key] == undefined)
                this._data[key] = defaultDict[key]
            Object.defineProperty( this, key, {
                get:() => {
                    if (properties.indexOf(key) == -1)
                        return this[key]
                    else
                        return this._data[key]
                },

                set:(value:any) => {
                    if (properties.indexOf(key) == -1)
                        this[key] = value
                    else
                        this._data[key] = value
                }

            } )
        }
    }
}
