import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {DeviceWiFiPassword} from './deviceWiFiPassword'
import {BleService} from '../../../services/bleService'


@Component({
    selector:'deviceWiFi',
    templateUrl:'deviceWiFi.html'
})
export class DeviceWiFi implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    nextWiFiIndex = 0
    wifiCount = 0
    wifiAPs:string[] = []
    viaBluetooth = false

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        public bleService:BleService,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.getWiFiCount()
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe()
    }


    async getWiFiCount() {
        console.log('Getting wifi count ')
        console.log('Connected ble ' + this.device._connectedBLE)
        console.log('Connected wifi ' + this.device._connectedWifi)
        console.log('Connected wifi ' + this.device.appVersion)

        if (this.device._connectedWifi || this.device._connectedBLE) {
            this.subscription = this.device.getObservable().subscribe(message => {
                this.processIncoming(message)
            })
        }

        if (String(this.device.appVersion).indexOf('.') > -1) {
            await this.commandService.publish(this.device, 'getWifiCount', false)
        } else {
            // LEGACY
            await this.commandService.publish(this.device, '{"command": "getWifiCount"}', false)
        }

    }

    async closeModal(data = '', sleep = 0) {
        await this.homeSession.sleep(sleep)
        await this.modalController.dismiss(data)
    }

    async processIncoming(message:string) {
        console.log('wifi: "' + message + '"')
        if (String(this.device.appVersion).indexOf('.') > -1) {
            if (message.startsWith('wfc/')) {
                this.wifiCount = Number(message.replace('wfc/', ''))
                this.wifiAPs = []
                console.log('wifi count: ' + this.wifiCount)
            }
            if (message.startsWith('wf/')) {
                let wifiAP = message.replace('wf/', '')
                if (this.wifiAPs.indexOf(wifiAP) == -1)
                    this.wifiAPs.push(wifiAP)
            }
        } else {
            // LEGACY
            if (this.wifiCount == 0) {
                this.wifiCount = Number(message)
                this.wifiAPs = []
                console.log('wifi count: ' + this.wifiCount)
            } else  {
                let wifiAP = message.split('|')[0]
                if (this.wifiAPs.indexOf(wifiAP) == -1)
                    this.wifiAPs.push(wifiAP)
            }
        }


        if (this.wifiCount > this.nextWiFiIndex) {
            if (String(this.device.appVersion).indexOf('.') > -1) {
                await this.commandService.publish(this.device, 'getWifi/' + this.nextWiFiIndex, false)
            } else {
                // LEGACY
                await this.commandService.publish(this.device, '{"command": "getWifi", "index":' + this.nextWiFiIndex + ' }', false)
            }
            this.nextWiFiIndex += 1
        }
    }

    async selectedWiFi(wifiAP:any) {
        console.log('WiFi selected ' + wifiAP)
        this.homeSession.selectedAP = wifiAP

        // this.modalController.dismiss({
        //     'dismissed':true
        // })

        try {
            // this.closeModal()
            const modal = await this.modalController.create({
                component:DeviceWiFiPassword,
                animated:true
            })

            modal.onDidDismiss().then((data) => {
                if (data.data == 'closeAll')
                    this.closeModal('closeAll', 10)
            })

            await modal.present()
        } catch (e) {
            console.log('Could not open modal ' + e)
        }

    }
}

