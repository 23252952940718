import {Component, NgZone, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {ActionLabels, Device} from '../../../models/device'
import {HomeSession} from '../../../services/homeSession'
import {CommandService} from '../../../services/commandService'
import {DeviceTriggerConfig} from './deviceTriggerConfig'
import moment from 'moment'


@Pipe({
    name:'orderTriggers'
})
export class OrderTriggers implements PipeTransform {
    transform(values:any[], key?:string, reverse?:boolean) {
        if (values)
            values.sort(this.compareItems)
        return values
    }

    private compareItems(a, b) {
        let x = Number(a.key)
        let y = Number(b.key)

        if (x == y)
            return 0
        if (x < y)
            return -1

        return 1
    }
}


@Component({
    selector:'deviceTriggers',
    templateUrl:'deviceTriggers.html'
})
export class DeviceTriggers implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    triggers:Number[]
    actionLabels:any
    datetime = moment()
    label:string = ''
    descriptions = []

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.actionLabels = ActionLabels
        this.device = this.homeSession.selectedDevice
        if (this.device.triggers == null)
            this.device.triggers = []
        this.triggers = this.device.triggers
        this.updateDescriptions()
    }

    ngOnDestroy() {
    }

    closeModal() {
        this.modalController.dismiss()
    }

    timerToTime(timer:number) {
        if (timer == 0)
            return 'Midnight'
        else if (timer == -1)
            return 'Sunrise'
        else if (timer == -2)
            return 'Noon'
        else if (timer == -3)
            return 'Sunset'
        if (timer > 0) {
            let hour:number = Math.floor(timer / 60)
            let minutes:number = timer - (hour * 60)
            this.datetime.set('hour', hour)
            this.datetime.set('minutes', minutes)
            return this.datetime.format("hh:mm a")
        }
    }

    async configTrigger($event, trigger) {

        if($event && $event.target.tagName != 'DIV')
            return

        this.homeSession.selectedTrigger = trigger
        const modal = await this.modalController.create({
            component:DeviceTriggerConfig,
            animated:true,
            cssClass:"modal-fullscreen"
        })
        await modal.present()

        modal.onDidDismiss().then((data) => {

            this.updateDescriptions()
        })
    }

    createNewTrigger() {
        try {
            let now = moment()
            let hour = now.hours()
            let minutes = 0
            let totalMinutes = (hour * 60) + minutes

            let newTrigger = {
                action: '',
                enabled:true,
                values:1,
                days:['1', '2', '3', '4', '5', '6', '7'],
                time:totalMinutes
            }

            this.configTrigger(null, newTrigger)

        } catch (e) {
            console.log('error' + e)
        }
    }

    async store() {
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setPlaylistDescription(item, index) {
        this.homeSession.playlists.subscribe(playlists => {
            for (let playlist of playlists) {
                if (item['playlist'] == playlist.index) {
                    let description =  playlist.playlist
                    if (item['seasons'])
                        description += ' or Current Season'
                    this.descriptions.splice(index, 1, description)
                }
            }
        })
    }

    updateDescriptions() {
        console.log('Updating descriptions')
        this.descriptions = []
        for (let index in this.triggers) {
            this.descriptions.push('')
            this.getDescription(this.triggers[index], index)
        }

    }


    getDescription(item, index) {
        if (item['action'] == 'power') {
            if (item['values']['power'] == '1')
                this.descriptions[index] = 'Turn On'
            if (item['values']['power'] == '0')
                this.descriptions[index] = 'Turn Off'
        }

        if (item['action'] == 'playlist') {
            this.setPlaylistDescription(item, index)
        }

        if (item['action'] == 'colors') {
            this.descriptions[index] = "Setting custom colors"
        }

        if (item['action'] == 'brightness') {
            this.descriptions[index] = "Set Brightness to " + item['values']['brightness'] + '%'
        }
    }
}

