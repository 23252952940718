import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {ActionLabels, Days, Device} from '../../../models/device'
import {HomeSession} from '../../../services/homeSession'
import {CommandService} from '../../../services/commandService'
import moment from 'moment'


@Component({
    selector:'deviceTriggerConfigTime',
    templateUrl:'deviceTriggerConfigTime.html'
})
export class DeviceTriggerConfigTime implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    schedule:any
    originalSchedule:any
    datetime = moment()

    actions:string[] = ['powerOn',
        'powerOff',
        'brightness',
        'colors',
        'playlist']

    actionLabels = ActionLabels
    dayLabels = Days
    trigger = {action:'', days:['1', '2', '3', '4', '5', '6', '7']}

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.trigger = this.homeSession.selectedTrigger
        this.schedule = this.trigger['time']
        this.originalSchedule = this.schedule

        if (Number(this.schedule) > -1) {
            let hour:number = Math.floor(this.schedule / 60)
            let minutes:number = this.schedule - (hour * 60)
            this.datetime.set('hour', hour)
            this.datetime.set('minutes', minutes)
        }
    }

    ngOnDestroy() {
    }

    async closeModal() {
        this.store()
        this.homeSession.buildProgram(this.device)
        await this.modalController.dismiss('closeAll')
    }

    setAction(action:string, values = null) {
        console.log('action ' + action)
        this.trigger['action'] = action
        this.trigger['values'] = values
        this.store()
    }

    async store() {
        if (this.device.triggers.indexOf(this.trigger) == -1)
            return
        await this.homeSession.updateDevice(this.device, {triggers:this.device.triggers})
    }

    setTime(value:string) {
        let parts = value.split(':')
        let hour:number = Number(parts[0])
        let minutes:number = Number(parts[1])
        this.datetime.set('hour', hour)
        this.datetime.set('minutes', minutes)
        this.schedule = (hour * 60) + minutes
        this.setTriggersTime(this.schedule)
    }

    setTriggersTime(number:number) {
        console.log('setting time to ', number)
        this.trigger['time'] = number
        this.store()
    }

    toggleDay(day:string) {
        if (this.trigger.days.indexOf(day) == -1)
            this.trigger.days.push(day)
        else
            this.trigger.days.splice(this.trigger.days.indexOf(day), 1)
        this.trigger.days.sort()
    }
}

