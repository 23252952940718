import {EventEmitter, Injectable, NgZone} from '@angular/core'
import {Subscription} from 'rxjs'
import {Device} from '../models/device'
import {HomeSession} from './homeSession'
import {Platform} from '@ionic/angular'
import {AngularFirestore} from '@angular/fire/firestore'
import moment from 'moment'
import {BluetoothLE, DescriptorParams} from '@ionic-native/bluetooth-le/ngx'
import {WriteDescriptorParams} from '@ionic-native/bluetooth-le'
import {Capacitor} from '@capacitor/core'


export class BleConnection {
    connectionEvent:EventEmitter<boolean> = new EventEmitter()

    serviceConnectId = '00FF'
    bleChannelId:string = 'FF01'
    sending = false
    messages:string[] = []
    lastSentTime:moment.Moment

    constructor(
        public bluetoothle:BluetoothLE,
        public device:Device,
        public zone:NgZone) {
        console.log('Device connection')
        this.connect()
    }

    async sendMessage(message:string) {
        if (this.device._connectedBLE == false) {
            return
        }

        try {
            let params = {address:this.device.bleServiceId}
            params['service'] = this.serviceConnectId
            params['characteristic'] = this.bleChannelId

            if (message.length < 20) {
                try {
                    let shortMessage = '>' + message + '<'
                    console.log('Sending ble short msg ' + shortMessage)
                    let bytes = this.bluetoothle.stringToBytes(shortMessage)
                    params['value'] = this.bluetoothle.bytesToEncodedString(bytes)
                    await this.bluetoothle.write(<WriteDescriptorParams>params)
                } catch (e) {
                    console.log('Error sending short message to device ' + this.device.device)
                    console.log('Error: ' + e.message)
                    console.log('Error 1.1: ' + params['address'])
                    console.log('Error 1.2: ' + params['service'])
                    console.log('Error 1.3: ' + params['characteristic'])
                    console.log('Error 1.4: ' + params['type'])
                    console.log('Error 1.5: ' + params['value'])
                }

            } else {
                let messages:string[] = message.match(/.{1,20}/g)
                messages.unshift('start')
                messages.push('end')

                for (message of messages) {
                    try {
                        console.log('sending message part ' + message)
                        let bytes = this.bluetoothle.stringToBytes(message)
                        params['value'] = this.bluetoothle.bytesToEncodedString(bytes)
                        await this.bluetoothle.write(<WriteDescriptorParams>params)
                    } catch (e) {
                        if (e.message.toString().indexOf('connected') > 0) {
                            console.log(this.device.device + ' disconnected, reconnecting')
                            this.device._connectedBLE = false
                            this.connect()
                            this.sendMessage(message)
                            return
                        }
                        console.log('Error sending message to device ' + this.device.device)
                        console.log('Error: ' + e.message)
                        console.log('Error 2.1: ' + params['address'])
                        console.log('Error 2.2: ' + params['service'])
                        console.log('Error 2.3: ' + params['characteristic'])
                        console.log('Error 2.4: ' + params['type'])
                        console.log('Error 2.5: ' + params['value'])
                        break
                    }
                }
            }
        } catch (e) {
            console.log('Error sending via ble ' + e.message)
        }

        await this.sleep(100)

        try {
            await this.read()
        } catch (e) {
            console.log('Error reading from ble ' + e.message)
        }

        await this.sleep(50)
        this.sending = false
        // parse any remaining messages
        this.next('', true)
    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }


    async next(message:string, okToSkip:boolean) {

        console.log(this.device.device + ' Add ble next message ' + message)

        if (this.device._connectedBLE == false) {
            if ((okToSkip == false) && (message)) {
                this.messages.push(message)
            }
            console.log(this.device.device + 'Not connected. Hold in queue, Return.')
        }

        if (this.sending) {
            if ((okToSkip == false) && (message)) {
                this.messages.push(message)
            }

            console.log(this.device.device + 'Already ble sending. Hold in queue, Return.')
            return
        }

        if (message) {
            if ((this.messages.length > 0) && (this.messages[this.messages.length - 1] == message))
                return
            this.messages.push(message)
        }

        console.log(this.device.device + ' no ble messages')

        if (this.messages.length == 0)
            return

        message = this.messages.shift()

        console.log(this.device.device + ' sending message ' + message + ' ' + this.messages)
        this.sending = true
        this.lastSentTime = moment()
        let lastSentTime_ = this.lastSentTime
        try {
            this.sendMessage(message)
        } catch (e) {
            console.log(this.device.device + ' error sending ble ' + this.device.device)
        }

        console.log(this.device.device + ' sending to false')

        await this.sleep(100)
        if (lastSentTime_ == this.lastSentTime) {
            this.sending = false
            this.next('', true)
        }
    }

    async read() {
        console.log('Reading message')
        let params = {}
        params['address'] = this.device.bleServiceId
        params['service'] = this.serviceConnectId
        params['characteristic'] = this.bleChannelId

        console.log('Reading message 1')
        let result = await this.bluetoothle.read(<DescriptorParams>params)
        if (result.value) {
            console.log('Reading message 2 ' + result.value)
            var bytes = this.bluetoothle.encodedStringToBytes(result.value)
            console.log('Reading message 3')
            var value = this.bluetoothle.bytesToString(bytes)

            console.log('Read msg ' + value)
            if (value != '') {
                this.device.nextMsg(value)
                console.log('Read msg ' + value)
            }
        }

    }

    parseReadResult(result) {
    }

    connect() {
        if (this.device.bleServiceId == '') {
            console.log('No bleServiceId returnin')
            return
        }

        console.log('Connecting to device ' + this.device.bleId)
        this.bluetoothle.connect({address:this.device.bleServiceId, autoConnect:true}).subscribe(
            result => {
                console.log('Connection ' + result)
                this.connectedCallback(this.device)
            },
            async error => {
                if (error.message.indexOf('close')) {
                    try {
                        await this.bluetoothle.disconnect({address:this.device.bleServiceId})
                    } catch (e) {
                        console.log('Error disconnecting ' + e.message)
                    }

                    try {
                        await this.bluetoothle.close({address:this.device.bleServiceId})
                    } catch (e) {
                        console.log('Error closing' + e.message)
                    }
                    await this.sleep(1000)
                    this.connect()
                }
                console.log('Error connecting to device ' + error.message)
            }
        )
    }

    async disconnect() {
        if (this.device._connectedBLE == false)
            return

        console.log('Disconnect ' + this.device.device)
        try {
            await this.bluetoothle.disconnect({address:this.device.bleServiceId})
        } catch (e) {
            console.log('Error disconnecting ' + e.message)
        }

        try {
            await this.bluetoothle.close({address:this.device.bleServiceId})
        } catch (e) {
            console.log('Error closing' + e.message)
        }

        this.device._connectedBLE = false
    }

    async connectedCallback(device:Device) {
        console.log('Connected callback for ' + device.bleId)
        this.zone.run(() => {
            console.log('force update the screen')
        })


        console.log('Services ')
        try {
            let result = await this.bluetoothle.discover({address:this.device.bleServiceId, clearCache:true})

            for (let service of result.services) {
                if (service.uuid != '00FF')
                    continue
                for (let char of service.characteristics) {
                    for (let descriptor of char.descriptors) {
                        console.log('descr ' + descriptor.uuid)
                    }
                    console.log('char ' + char.uuid)
                    console.log('Services ' + service.uuid)

                    device._connectedBLE = true
                    this.connectionEvent.emit(true)
                }
            }
        } catch (e) {
            console.log('Could not get services ' + e.message)
            if (e.message.toString().indexOf('connected') > 0) {
                await this.disconnect()
                await this.sleep(2000)
                await this.connect()
            }
        }


        this.next('', false)
    }
}

@Injectable({
    providedIn:'root'
})
export class BleService {

    devices:any[] = []
    knownDevices:any[] = []
    newDevices:any[] = []
    scanSubscription:Subscription = null
    scanAttempts = 0
    maxAttempts = 3
    scanning = false
    lastScan:number = 0
    initialized:boolean = false
    connections:any = {}
    bleSubscription:Subscription = null
    bleInit:boolean = false
    message:string = ''
    subscription:Subscription

    constructor(public bluetoothle:BluetoothLE,
                private fs:AngularFirestore,
                public platform:Platform,
                private homeSession:HomeSession,
                public zone:NgZone) {
    }


    async init() {
        if (this.initialized)
            return this.scan()

        this.initialized = true

        this.devices = []
        this.newDevices = []
        this.knownDevices = []

        //Subscribe on pause i.e. background
        this.platform.pause.subscribe(() => {
            this.deviceSleep()
        })

        //Subscribe on resume i.e. foreground
        this.platform.resume.subscribe(() => {
            this.deviceAwake()
        })

        await this.sleep(10)
        return this.deviceAwake()
    }

    async deviceSleep() {
        console.log('Go to sleep ')
        for (let device of this.homeSession.devices) {
            if (this.connections[device.bleId] != null) {
                console.log('Go to sleep device ' + device.bleId)
                await this.connections[device.bleId].disconnect()
            }
        }
        console.log('Go to sleep stop scanning')
        let isScanning = await this.bluetoothle.isScanning()
        if (isScanning.isScanning)
            await this.bluetoothle.stopScan()
        console.log('Go to sleep stop unsubscribe')
        if (this.bleSubscription) {
            this.bleSubscription.unsubscribe()
            this.bleSubscription = null
        }
        console.log('Done going to sleep ')
    }

    async deviceAwake() {
        console.clear()
        this.lastScan = 0
        try {
            await this.scan()
        } catch (e) {
            console.log('Error in setting up scanning ' + e.message)
        }

        for (let device of this.homeSession.devices) {
            if (this.connections[device.bleId] != null) {
                await this.connections[device.bleId].connect()
            } else {
                this.connections[device.bleId] = new BleConnection(this.bluetoothle, device, this.zone)
            }
        }
    }

    async scan() {
        for (let device of this.newDevices) {
            for (let homeDevice of this.homeSession.devices) {
                if (homeDevice.bleId == device.bleId) {
                    this.newDevices.splice(this.newDevices.indexOf(device), 1)
                    console.log('dev device ' + device.device)
                    console.log('dev homeid ' + device.homeId)
                    console.log('dev timezone ' + device.timezone)
                }
            }
        }

        console.log('Scanning for Bluetooth LE Devices')

        this.maxAttempts = 0
        this.scanAttempts = 0

        return new Promise((resolve, reject) => {
            this.setupScanning(resolve, reject)
        })
    }

    async setupScanning(resolve, reject) {
        console.log('setup scanning ')
        if (this.bleSubscription == null) {
            console.log('Creating ble init ')
            // this.bluetoothle.enable()
            try {
                this.bleSubscription = this.bluetoothle.initialize().subscribe((bleResult) => {
                        console.log('BLE Initialized ' + bleResult.status)
                        this.setupScanning2(resolve, reject)
                        this.bleInit = true
                    },
                    error => {
                        console.log("Could not init ble " + error.message)
                    })
            } catch (e) {
                console.log('Could not init ble 2 ' + e.message)
            }
        } else {
            if (this.bleInit)
                this.setupScanning2(resolve, reject)
        }
    }

    async setupScanning2(resolve, reject) {
        console.log('Capacitor.platform ' + Capacitor.platform)
        if (Capacitor.platform == 'android') {
            let permission = await this.bluetoothle.hasPermission()
            console.log('Has location permission ' + permission.hasPermission)
            if (permission.hasPermission == false)
                await this.bluetoothle.requestPermission()
        }

        console.log('Start scanning 1')
        this.lastScan = moment().unix()

        if (this.scanAttempts > this.maxAttempts) {
            this.scanning = false
            resolve("Done")
            return
        }

        console.log('Start scanning 2')
        this.scanAttempts += 1
        this.scanning = true

        console.log('Start scanning 3')

        this.scanSubscription = this.bluetoothle.startScan({'allowDuplicates':true}).subscribe(
            device => {
                this.zone.run(() => {
                    this.onDeviceDiscovered(device)
                })
            },
            error => {
                console.log('Error scanning ' + error.message)
                this.scanning = false
                reject(error)
                console.log(error)
                this.scanError(error)
            }
        )

        setTimeout(this.stopScanning.bind(this), 3000 * (this.scanAttempts + 1), resolve, reject)
    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async stopScanning(resolve, reject) {
        console.log('Scanning stopped')
        this.scanning = false
        this.bluetoothle.stopScan()
        if (this.devices.length == 0) {
            await this.sleep(2)
            this.setupScanning(resolve, reject)
        } else {
            this.scanAttempts = 0
            resolve('Done scanning')
        }
    }

    getBleConnectionForDevice(device:Device):BleConnection {
        if (this.connections[device.bleId] == null) {
            this.connections[device.bleId] = new BleConnection(this.bluetoothle, device, this.zone)
        }
        return this.connections[device.bleId]
    }

    async onDeviceDiscovered(bleDevice) {

        if ((bleDevice.name == null)
            || (bleDevice.name.toLowerCase().indexOf('emolights') == -1)) {
            return
        }

        let id:string = bleDevice.name.split(' ')[1]
        console.log('Found emo device ' + bleDevice.name)

        if (id.indexOf(':') > 1)
            id = id.replace(":", "")

        console.log('Using device Id ' + id)

        if (this.connections[id] != null)
            return

        let device:Device = null

        for (let item of this.homeSession.devices) {
            if (item.bleId == id) {
                device = item
                device.bleServiceId = bleDevice.address
                console.log('Device bleid ' + item.bleId)
                console.log('Device address ' + bleDevice.address)
                console.log('Device advertisement ' + this.bluetoothle.bytesToEncodedString(bleDevice.advertisement))
                console.log('Device rssi ' + bleDevice.rssi)
                device.timezone = String(Intl.DateTimeFormat().resolvedOptions().timeZone)
                break
            }
        }


        if (this.knownDevices.indexOf(id) != -1) {
            console.log('Known device ' + id)
            return
        }

        this.knownDevices.push(id)

        if (device == null) {
            console.log('Geting devices from firestore')
            let result = await this.fs.collection('lights/', result => result.where('bleId', '==', id)).get().toPromise()
            let devices = []
            if (!result.empty) {
                result.forEach(item => {
                    devices.push(item.data())
                })
            }

            if (devices.length > 0) {
                console.log('Device found in firestore')

                device = new Device(devices[0])

                this.newDevices.push(device)
                device.bleId = id
                console.log('Id:' + id + ' appversion ' + device.appVersion)

                device.bleServiceId = bleDevice.address
                device.timezone = String(Intl.DateTimeFormat().resolvedOptions().timeZone)

                // Copy the settings from an existing device
                if (this.homeSession.devices.length > 0) {
                    if (this.homeSession.devices[0].triggers == null)
                        this.homeSession.devices[0].triggers = []
                    let defaultTriggers = JSON.parse(JSON.stringify(this.homeSession.devices[0].triggers))
                    device.seasons = Object.assign({}, this.homeSession.devices[0].seasons)
                    device.triggers = defaultTriggers
                    device.program = this.homeSession.devices[0].program
                }

                if (this.connections[device.bleId] == null) {
                    console.log('Creating connection for new device ' + device.bleId)
                    this.connections[device.bleId] = new BleConnection(this.bluetoothle, device, this.zone)
                }

                device.device = bleDevice.name
                device._bleName = bleDevice.name
                device._new = true
                device.timezone = String(Intl.DateTimeFormat().resolvedOptions().timeZone)

                device.homeId = this.homeSession.home.uid

                // Todo: warn that a user already registered this device
                // if (device.users == "")
                this.determineVersion(device)

                device.users = [this.homeSession.user.uid]
            } else (
                this.message = 'Found unregistered device ' + bleDevice.name
            )
        }

        if (device == null) {
            console.log('Not registered device not found')
            this.deviceNotFound(id)
            return
        }

        if (this.devices.indexOf(device.bleId) == -1)
            this.devices.push(device.bleId)
    }

    getConnection(id:string):BleConnection {
        return this.connections[id]
    }

    async scanError(error) {
        console.log('Error ' + error)
    }

    async deviceNotFound(uid) {
        console.log('Device not found ' + uid)
    }

    publishMessage(device, msg, okToSkip = false) {
        let connection:BleConnection = this.getBleConnectionForDevice(device)
        connection.next(msg, okToSkip)
    }

    private determineVersion(device:Device) {
        // check with current version
        console.log('Determining new version')
        this.publishMessage(device, '{"command": "getInfo"}', false)
        this.subscription = device.getObservable().subscribe(message => {
            console.log('new device info ' + message)
            if (message.startsWith('{')) {
                try {
                    let info = JSON.parse(message)
                    if (info.appVersion)
                        device.appVersion = info.appVersion
                } catch (e) {
                    console.log('Could not parse info message ' + message)
                }
            } else {
                let parts:string[] = message.split(' ')
                if (parts.length > 2) {
                    console.log('New device info appversion ' + parts[2])
                    device.appVersion = parts[2]
                }
            }

            this.subscription.unsubscribe()
        })
    }
}
