import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {AngularFirestore} from '@angular/fire/firestore'
import {Season} from '../../../models/season'
import moment from 'moment'
import {timeout} from 'rxjs/operators'


@Component({
    selector:'deviceSeasonConfig',
    templateUrl:'deviceSeasonConfig.html'
})
export class DeviceSeasonConfig implements OnInit, OnDestroy {
    device:Device
    season:Season
    source:any = {}

    startDate:moment.Moment = moment()
    endDate:moment.Moment = moment()


    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        public zone:NgZone,
        ) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.season = this.homeSession.selectedSeason

        if (this.device.seasonDates[this.homeSession.selectedSeason.index] != undefined) {
            this.source = this.device.seasonDates[this.homeSession.selectedSeason.index]
        } else {
            this.source = {}
            this.source['startDay'] = Number(this.season.startDay)
            this.source['endDay'] = Number(this.season.endDay)
            this.source['startMonth'] = Number(this.season.startMonth)
            this.source['endMonth'] = Number(this.season.endMonth)
        }

        this.source.startDay = Number(this.source.startDay)
        this.source.startMonth = Number(this.source.startMonth)

        this.source.endDay = Number(this.source.endDay)
        this.source.endMonth = Number(this.source.endMonth)

        this.updateDates(true)
    }


    ngOnDestroy() {
    }

    closeModal() {
        this.modalController.dismiss('closeAll')
    }

    setDates() {
        this.startDate.set('month', this.source.startMonth - 1)
        this.startDate.set('date', this.source.startDay)

        this.endDate.set('month', this.source.endMonth - 1)
        this.endDate.set('date', this.source.endDay)
    }

    updateDates(settingStart) {
        this.setDates()

        if (this.endDate < this.startDate) {
            if (settingStart) {
                this.source.endDay = this.source.startDay
                this.source.endMonth = this.source.startMonth
                this.endDate.set('month', this.source.endMonth - 1)
                this.endDate.set('date', this.source.endDay)
            } else {
                this.source.startDay = this.source.endDay
                this.source.startMonth = this.source.endMonth
                this.startDate.set('month', this.source.startMonth - 1)
                this.startDate.set('date', this.source.startDay)
            }
        }

        this.device.seasonDates[this.season.index] = {}
        this.device.seasonDates[this.season.index].startDay = this.source.startDay
        this.device.seasonDates[this.season.index].startMonth = this.source.startMonth
        this.device.seasonDates[this.season.index].endDay = this.source.endDay
        this.device.seasonDates[this.season.index].endMonth = this.source.endMonth
    }

    resetToDefaults() {
        this.source.startDay = this.season.startDay
        this.source.endDay = this.season.endDay
        this.source.startMonth = this.season.startMonth
        this.source.endMonth = this.season.endMonth
        this.setDates()
        delete this.device.seasonDates[this.season.index.toString()]
        this.store()
    }

    async store() {
        await this.homeSession.updateDevice(this.device, {'seasonDates':this.device.seasonDates})
        await this.homeSession.buildProgram(this.device)
    }

    setStartDate(source) {
        console.log('New Start Date   ' + source)
        let newDate = moment(source, "YYYY-MM-DD")

        this.source.startDay = newDate.date()
        this.source.startMonth = newDate.month() + 1

        console.log('New Start Date   ' + this.source.startMonth + '-' + this.source.startDay)

        this.updateDates(true)
        this.store()
    }

    setEndDate(source) {
        console.log('New End Date   ' + source)
        let newDate = moment(source, "YYYY-MM-DD")

        this.source.endDay = newDate.date()
        this.source.endMonth = newDate.month() + 1

        console.log('New End Date   ' + this.source.endMonth + '-' + this.source.endDay)

        this.updateDates(false)
        this.store()
    }

}

