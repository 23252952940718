import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../../services/homeSession'
import {Router} from '@angular/router'
import {CommandService} from '../../../services/commandService'
import {Device} from '../../../models/device'
import {BleService} from '../../../services/bleService'


@Component({
    selector:'deviceReset',
    templateUrl:'deviceReset.html'
})
export class DeviceReset implements OnInit, OnDestroy {
    device:Device

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController,
        public bleService:BleService,
        private zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
    }

    ngOnDestroy() {
    }

    async closeModal(data='') {
        console.log('closing with data ' + data)
        await this.modalController.dismiss(data)
    }

    async reset() {
        this.commandService.publish(this.device, "reset", false)

        this.device.device = ''
        this.device.timezone = ''
        this.device.seasons = {}
        this.device.seasonsDates = {}
        this.device.triggers = {}
        this.device.users = []
        this.device.homeId = ''

        let defaultSettings = {}
        defaultSettings['ip'] = ''
        defaultSettings['homeId'] = ''
        defaultSettings['timezone'] = ''
        defaultSettings['seasons'] = {}
        defaultSettings['seasonsDates'] = {}
        defaultSettings['timers'] = {}
        defaultSettings['users'] = []
        defaultSettings['device'] = ''
        defaultSettings['googleHome'] = false

        this.homeSession.devices.splice(this.homeSession.devices.indexOf(this.device), 1)
        await this.homeSession.updateDevice(this.device, defaultSettings)

        if (this.device._connectedBLE) {
            this.device.ip = ''
            this.device.device = this.device._bleName
            this.device._new = true
            this.device.timezone = String(Intl.DateTimeFormat().resolvedOptions().timeZone)
            this.device.homeId = this.homeSession.home.uid
            this.bleService.newDevices.push(this.device)
        }

        await this.closeModal('closeAll')
    }
}

