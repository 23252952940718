import {Component, OnDestroy, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {Observable} from 'rxjs/Observable'
import {AngularFirestore} from '@angular/fire/firestore'
import {ColorEvent} from 'ngx-color'
import {Device} from '../../models/device'
import {RGBColor} from './auto/colorPicker/color-slider/color-slider.component'
import {HomeSession} from '../../services/homeSession'
import {CommandService} from '../../services/commandService'


@Component({
    selector:'deviceColors',
    templateUrl:'deviceColors.html'
})
export class DeviceColors implements OnInit, OnDestroy {
    subscription:Subscription = null
    device:Device
    trigger = null
    playlists:Observable<any[]>
    demoIndices = {}
    hue:string = 'rgba(237,0,255,1)'
    color:string
    startColor:RGBColor = new RGBColor(254, 0, 255)
    hueColor:RGBColor = new RGBColor(254, 0, 255)
    rgbColor:RGBColor = new RGBColor(255, 0, 255)
    colors:RGBColor[] = []
    selectedIndex = -1

    constructor(
        protected fs:AngularFirestore,
        public homeSession:HomeSession,
        public router:Router,
        private commandService:CommandService,
        public modalController:ModalController) {
        this.device = this.homeSession.selectedDevice

        if ((!this.device.colors) || (this.device.colors.length > 5)) {
            this.device.colors = [0xff00ff]
        }

        this.trigger = {}
        this.trigger['values'] = {}
        this.trigger['values']['seasons'] = true
        this.trigger['values']['colors'] = this.device.colors.concat()
        this.trigger['values']['animate'] = true
        this.trigger['values']['fade'] = true
        this.trigger['values']['animation'] = true
        this.trigger['values']['fadeSpeed'] = this.device.colorsFadeSpeed || 100
        this.trigger['values']['animationSpeed'] = this.device.colorsAnimateSpeed || 100
        this.trigger['values']['duration'] = this.device.colorsDuration || 100

        this.device._rgbColors = []

        for (let color of this.trigger['values']['colors']) {
            let rgb = new RGBColor()
            rgb.fromNumber(Number(color))
            this.device._rgbColors.push(rgb)
        }

    }

    ngOnInit() {
        this.rgbColor = this.startColor


        this.switchToColor(this.trigger['values']['colors'].length - 1)
    }

    ngOnDestroy() {
    }


    async closeModal() {
        await this.sendColors()
        await this.modalController.dismiss({
            'dismissed':true
        })
    }

    async sendColors() {
        let command = 'colors/' + this.trigger['values']['duration']
        command += ' ' + this.trigger['values']['fadeSpeed']
        command += ' ' + this.trigger['values']['animationSpeed']
        command += ' ' + this.trigger['values']['colors'].length

        for (let color of this.trigger['values']['colors']) {
            command += ' ' + color
        }

        this.commandService.publish(this.device, command)
    }

    async sendCurrentColor() {
        let command = 'colors/' + this.trigger['values']['duration']
        command += ' ' + this.trigger['values']['fadeSpeed']
        command += ' ' + this.trigger['values']['animationSpeed']
        command += ' ' + 1
        command += ' ' + this.rgbColor.toNumber()
        console.log('sending color ' + command)
        this.commandService.publish(this.device, command)
    }

    async previewColor() {
        let command = 'colors/10 1 1 1'
        command += ' ' + this.rgbColor.toNumber()
        console.log('sending color ' + command)
        this.commandService.publish(this.device, command)
    }

    setBrightness(value:any) {
        console.log('setting value to ' + value)
        this.homeSession.selectedTrigger['values']['brightness'] = value
    }

    changeComplete($event:ColorEvent) {
        console.log($event.color)
    }

    setHue($event:string) {
        this.hue = $event
        this.color = $event
        console.log('hue ' + this.hue)
    }

    setRGBHueColor($event:RGBColor) {
        this.rgbColor = $event
        this.hueColor = this.rgbColor.clone()
        console.log('rgbhuecolor ', this.hueColor.toString())
    }

    async setRGBColor($event:RGBColor) {
        this.rgbColor = $event
        if (this.selectedIndex > -1)
            this.trigger['values']['colors'][this.selectedIndex] = this.rgbColor.toNumber()
        await this.previewColor()
    }

    setColor($event:string) {
        this.color = $event
    }

    addColor() {
        console.log('this.rgbColor' + this.rgbColor)
        this.trigger['values']['colors'].push(this.rgbColor.toNumber())
        this.selectedIndex = this.trigger['values']['colors'].length - 1
        this.device.colors.push(this.rgbColor.toNumber())
        this.device._rgbColors.push(this.rgbColor)
    }


    setSwatchColor(color) {
        let rgbColor:RGBColor = new RGBColor()
        rgbColor.fromNumber(color)
        return rgbColor.toHex()
    }

    removeColor(index) {
        this.trigger['values']['colors'].splice(index, 1)
        this.selectedIndex = Math.max(0, index)
        this.selectedIndex = Math.min(this.trigger['values']['colors'].length - 1, index)
        this.device.colors.splice(index, 1)
        this.device._rgbColors.splice(index, 1)
        this.switchToColor(this.selectedIndex)
    }

    switchToColor(index) {
        this.selectedIndex = index
        let color = this.trigger['values']['colors'][index]

        let newColor = new RGBColor()
        newColor.fromNumber(color)
        this.rgbColor = newColor.clone()

        this.startColor = newColor.clone()
        newColor.saturate()
        this.hueColor = newColor.clone()

        this.color = color
        console.log(this.hueColor.toString())
    }

}

