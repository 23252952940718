import {Component, EventEmitter, OnInit, Output} from '@angular/core'

import {Observable} from 'rxjs/Observable'
import {Platform} from '@ionic/angular'
import {AngularFireAuth} from '@angular/fire/auth'
import {Home} from '../../models/home'
import {AngularFirestore} from '@angular/fire/firestore'
import {HomeSession} from '../../services/homeSession'
import {Router} from '@angular/router'
import {cfaSignIn} from 'capacitor-firebase-auth'
import firebase from 'firebase/app'
import 'firebase/auth'

import {Plugins} from '@capacitor/core'

const {Device, SignInWithApple} = Plugins

@Component({
    selector:'google-login',
    templateUrl:'google-login.html'
})
export class GoogleLoginComponent implements OnInit {

    @Output()
    initialized = new EventEmitter<string>()

    user:Observable<firebase.User>
    loggedIn = false
    subscription = null
    showLogin:boolean = false
    showAppleSignIn:boolean = false

    constructor(private afAuth:AngularFireAuth,
                private platform:Platform,
                private fs:AngularFirestore,
                private router:Router,
                private homeSession:HomeSession) {
        this.user = this.afAuth.authState
        this.afAuth.authState.subscribe(user => this.updateLoginState(user))
        this.homeSession.devicesEvent.subscribe(result => this.devicesLoaded())
    }

    async ngOnInit() {
        // Only show the Apple sign in button on iOS
        const device = await Device.getInfo()
        this.showAppleSignIn = device.platform === 'ios'
    }

    updateLoginState(user:firebase.User) {
        if (user == null) {
            this.loggedIn = false
            this.initialized.emit('login')
            this.showLogin = true
        } else {
            this.loggedIn = user.uid != ''
            console.log('Logged-in ' + this.loggedIn + ' ' + user.displayName)
        }

        if (this.loggedIn) {
            this.homeSession.user = user
            this.getHomeInfo(user)
            return
        }
    }

    getHomeInfo(user) {
        this.subscription = this.fs.collection('homes', result => result.where('users', 'array-contains', user.uid)).get().subscribe(
            result => {
                this.subscription.unsubscribe()
                if (result.size > 0) {
                    this.homeSession.home = new Home(result.docs[0].data())
                    console.log(this.homeSession.home.uid)
                    console.log(result.docs[0].data())
                } else {
                    let home:Home = new Home()
                    home.uid = this.fs.createId()
                    home.users.push(user.uid)
                    this.homeSession.home = home
                    this.fs.doc('homes/' + home.uid).set(home.getDict())
                }
                this.homeSession.init()
            })
    }

    googleLogin() {
        console.log('logging in')

        if (this.platform.is('cordova')) {
            this.nativeGoogleLogin()
        } else {
            this.webGoogleLogin()
        }
    }


    nativeGoogleLogin() {
        cfaSignIn('google.com').subscribe(
            (user:firebase.User) => {
                console.log('user', user.displayName)
                user.getIdToken().then(
                    token => {
                        console.log('got token', user.displayName)
                        firebase.auth.GoogleAuthProvider.credential(token)
                    }
                )
            }
        )
    }

    async webGoogleLogin() {
        try {
            const provider = new firebase.auth.GoogleAuthProvider()
            const credential = await this.afAuth.signInWithPopup(provider)

        } catch (err) {
            console.log(err)
        }

    }

    private devicesLoaded() {
        this.initialized.emit('devices')
    }


    async createFirebaseuser(appleResponse) {
        // Create a custom OAuth provider
        const provider = new firebase.auth.OAuthProvider('apple.com')

        // Create sign in credentials with our token
        const credential = provider.credential({
            idToken:appleResponse.identityToken
        })

        // Call the sign in with our created credentials
        const userCredential = await this.afAuth.signInWithCredential(credential)

        // Update the user document in Firestore
        this.updateLoginState(userCredential.user)
    }

    openAppleSignIn() {
        console.log('singing in with apple')
        try {
            SignInWithApple.Authorize().then(async res => {
                console.log('Got response')
                if (res.response && res.response.identityToken) {
                    this.createFirebaseuser(res.response)
                } else {
                    this.presentAlert()
                }
            }).catch(response => {
                console.log(response)
            })

        } catch (e) {
            console.log(e.message)
        }
    }

    async presentAlert() {
        console.log('Presenting alert')
    }
}
