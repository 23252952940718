import {Component, NgZone, OnInit} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {HomeSession} from '../../services/homeSession'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {CommandService} from '../../services/commandService'
import {Device} from '../../models/device'
import {DeviceToMusic} from './music/deviceToMusic'
import {DevicePlaylists} from './manual/devicePlaylists'
import {DeviceConfig} from './config/deviceConfig'
import moment from 'moment'
import {DeviceTriggers} from './auto/deviceTriggers'
import {DeviceColors} from './deviceColors'

declare var require: any
var SunCalc = require('suncalc');

@Component({
    selector:'devices-details',
    templateUrl:'deviceDetails.html'
})
export class DeviceDetails implements OnInit {

    subscription:Subscription = null
    device:Device
    brightness:Number = 75
    lastBrightness:Number
    modal:any
    pause:boolean = false
    deviceSubscription:Subscription

    constructor(
        public homeSession:HomeSession,
        public router:Router,
        public commandService:CommandService,
        public modalController:ModalController,
        public zone:NgZone) {
    }

    ngOnInit() {
        this.device = this.homeSession.selectedDevice
        this.brightness = this.device.brightness
        this.updateInfo()
        this.deviceSubscription = this.device.getObservable().subscribe(value => {
            console.log('Playlist got values ' + value)
        })
    }


    async closeModal(data = '', sleep = 0) {
        if (this.subscription)
            this.subscription.unsubscribe()
        if (this.deviceSubscription)
            this.deviceSubscription.unsubscribe()
        await this.homeSession.sleep(sleep)
        await this.modalController.dismiss(data)
    }

    async playlists() {
        const modal = await this.modalController.create({
            component:DevicePlaylists,
            animated:true,
            cssClass: "modal-fullscreen"
        })

        return await modal.present()
    }

    async colors() {
        const modal = await this.modalController.create({
            component:DeviceColors,
            animated:true,
            cssClass: "modal-fullscreen"
        })

        return await modal.present()
    }

    async schedules() {
        const modal = await this.modalController.create({
            component:DeviceTriggers,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        return await modal.present()
    }


    togglePower() {
        this.setPower(!this.device.power)
    }

    togglePause() {
        this.device.pause = !this.device.pause
        let command = 'pause/0'
        if (this.device.pause)
            command = 'pause/1'
        this.commandService.publish(this.device, command)
    }

    getColor() {
            return '#FFFFFF'
    }

    setAutoSchedule() {
        this.setMode(this.device._AUTO_MODE)
    }

    setManual() {
        this.setMode(this.device._MANUAL_MODE)
    }

    setMode(mode) {
        this.device.mode = mode
        this.commandService.publish(this.device, 'mode/' + this.device.mode)
        this.homeSession.updateDevice(this.device, {'mode':this.device.mode})

    }

    skip() {
        this.commandService.publish(this.device, 'skip/0')
    }

    back() {
        this.commandService.publish(this.device, 'back/0')
    }

    setPower(power) {
        this.device.power = power
        this.device.pause = false
        this.commandService.publish(this.device, 'power/' + (this.device.power ? '1':'0'))
        this.homeSession.updateDevice(this.device, {'power':this.device.power})
    }

    async syncToMusic() {
        const modal = await this.modalController.create({
            component:DeviceToMusic,
            animated:true,
            cssClass: "modal-fullscreen"
        })
        return await modal.present()
    }

    setBrightness(value, okToSkip:boolean = true) {
        this.device.brightness = value
        this.commandService.publish(this.device, 'brightness/' + value, okToSkip)
    }


    async configDevice() {
        this.modal = await this.modalController.create({
            component:DeviceConfig,
            animated:true,
            cssClass: "modal-fullscreen"
        })

        this.modal.onDidDismiss().then((data) => {
            if (data.data == 'closeAll')
                this.closeModal('closeAll', 10)
        })

        return await this.modal.present()
    }

    setDelayedDim2() {
        if (this.lastBrightness == this.device.brightness)
            return
        this.lastBrightness = this.device.brightness
        this.homeSession.updateDevice(this.device, {'brightness':this.device.brightness})
    }

    setDelayedDim(dimValue:Number, okToSkip:boolean) {
        setTimeout(() => {
            this.setBrightness(dimValue, okToSkip)
        }, 10)
        setTimeout(() => {
            this.setBrightness(this.device.brightness, okToSkip)
        }, 100)
        setTimeout(() => {
            this.setDelayedDim2()
        }, 1000)
    }

    async updateInfo() {
        await this.commandService.publish(this.device, 'getInfo')
    }

    getAutoModeDetails() {
        let result: String = ''
        if (this.device.playlist == "0000")
            result = 'Set to OFF according to schedule.'
        else
            result ='Automatically set to ' + this.device._playlistName

        return result
    }

    getAutoModeDetails2() {
        let result = '('
        let triggers =[]
        for (let trigger of Object.entries(this.device.triggers)) {
            let time = this.timerToTime(trigger['time'])
            let action = this.valueToAction(trigger['action'] as string)

            triggers.push(action + ' at ' + time)
        }
        result += triggers.join(', ') + ')'
        return result
    }

    valueToAction(value:string) {
        if(value == 'season')
            return 'Season'
        else if(value == 'off-season')
            return 'Off Season'
        else if(value == 'off')
            return 'OFF'
        return ''
    }


    timerToTime(timer:number) {
        let times = SunCalc.getTimes(Date.now(), this.device.lat, this.device.lon)

        let sunset = moment(times.sunset).format("hh:mm a")
        let sunrise = moment(times.sunrise).format("hh:mm a")

        let datetime = moment()

        if(timer == 0)
            return 'Midnight'
        else if(timer == -1)
            return sunrise
        else if(timer == -2)
            return 'Noon'
        else if(timer == -3)
            return sunset
        if (timer > 0) {
            let hour:number = Math.floor(timer / 60)
            let minutes:number = timer - (hour * 60)
            datetime.set('hour', hour)
            datetime.set('minutes', minutes)
            return datetime.format("hh:mm a")
        }

    }
}

