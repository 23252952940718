import {NgModule} from '@angular/core'
import {PreloadAllModules, RouterModule, Routes} from '@angular/router'
import {LoginPage} from '../pages/login/login'
import {DevicesPage} from '../pages/devices/devices'
import {ProfilePage} from '../pages/profile/profile'
import {AuthGuard} from '../services/authGuard'

const routes: Routes = [
    {path:'', component:LoginPage},
    {path:'login', component:LoginPage},
    {path:'devices', component:DevicesPage, canActivate:[AuthGuard]},
    {path:'profile', component:ProfilePage, canActivate:[AuthGuard]},
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRouting {}
