import {CUSTOM_ELEMENTS_SCHEMA, enableProdMode, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {RouteReuseStrategy} from '@angular/router'

import {IonicModule, IonicRouteStrategy} from '@ionic/angular'
import {SplashScreen} from '@ionic-native/splash-screen/ngx'
import {StatusBar} from '@ionic-native/status-bar/ngx'

import {AppRouting} from './app-routing'
import {AppComponent} from './app.component'
import {AngularFireAuthModule} from '@angular/fire/auth'

import firebase from 'firebase/app'
import 'firebase/app'

import {LoginPage} from '../pages/login/login'
import {GoogleLoginComponent} from '../components/google-login/google-login'
import {HomeSession} from '../services/homeSession'
import {WebSocketService} from '../services/webSocketService'
import {AngularFireModule} from '@angular/fire'
import {HTTP} from '@ionic-native/http/ngx'
import {HttpClientModule} from '@angular/common/http'
import {ProfilePage} from '../pages/profile/profile'
import {DevicesPage, SortDevices} from '../pages/devices/devices'
import {SubscriptionPage} from '../pages/subscription/subscription'
import {PubNubAngular} from 'pubnub-angular2'
import {MenuOptionsComponent} from '../components/menu-options/menu-options'
import {FormsModule} from '@angular/forms'
import {AuthGuard} from '../services/authGuard'
import {Insomnia} from '@ionic-native/insomnia/ngx'
import {DeviceDetails} from '../pages/devices/deviceDetails'
import {CommandService} from '../services/commandService'
import {DevicePlaylists, OrderPlaylists} from '../pages/devices/manual/devicePlaylists'
import {ModalHeader} from '../pages/devices/modalHeader'
import {DeviceName} from '../pages/devices/config/deviceName'
import {DeviceWiFi} from '../pages/devices/config/deviceWiFi'
import {DeviceReset} from '../pages/devices/config/deviceReset'
import {DeviceTriggers, OrderTriggers} from '../pages/devices/auto/deviceTriggers'
import {DeviceAutoSchedule} from '../pages/devices/auto/deviceAutoSchedule'
import {DeviceToMusic} from '../pages/devices/music/deviceToMusic'
import {DeviceSeasons, OrderSeasons} from '../pages/devices/auto/deviceSeasons'
import {DeviceConfig} from '../pages/devices/config/deviceConfig'
import {DeviceWiFiPassword} from '../pages/devices/config/deviceWiFiPassword'
import {DeviceSeasonConfig} from '../pages/devices/auto/deviceSeasonConfig'
import {DeviceTriggerConfig} from '../pages/devices/auto/deviceTriggerConfig'
import {BluetoothLE} from '@ionic-native/bluetooth-le/ngx'
import {CurrentPlaylist} from '../pages/devices/currentPlaylist'
import {ChoosePlaylist} from '../pages/devices/auto/choosePlaylist'
import {ChooseBrightness} from '../pages/devices/auto/chooseBrightness'
import {ChooseColors} from '../pages/devices/auto/chooseColors'
import {ColorPickerComponent} from '../pages/devices/auto/colorPicker/color-picker.component'
import {ColorSliderComponent} from '../pages/devices/auto/colorPicker/color-slider/color-slider.component'
import {ColorPaletteComponent} from '../pages/devices/auto/colorPicker/color-palette/color-palette.component'
import {DeviceTriggerConfigTime} from '../pages/devices/auto/deviceTriggerConfigTime'
import {DeviceTriggerConfigAction} from '../pages/devices/auto/deviceTriggerConfigAction'
import {DeviceColors} from '../pages/devices/deviceColors'


const firebaseConfig = {
    apiKey:"AIzaSyCuZbp4AhRIivY1mAgY6CGqBnkr77sKkxE",
    authDomain:"emolights-222122.firebaseapp.com",
    databaseURL:"https://emolights-222122.firebaseio.com",
    projectId:"emolights-222122",
    storageBucket:"emolights-222122.appspot.com",
    messagingSenderId:"316803439151"
}

firebase.initializeApp(firebaseConfig)

enableProdMode()

@NgModule({
    declarations:[AppComponent,
        GoogleLoginComponent,
        OrderSeasons,
        OrderPlaylists,
        OrderTriggers,
        LoginPage,
        DeviceConfig,
        ProfilePage,
        DevicesPage,
        SubscriptionPage,
        MenuOptionsComponent,
        DeviceDetails,
        DevicePlaylists,
        ChoosePlaylist,
        ChooseBrightness,
        ChooseColors,
        DeviceSeasons,
        ModalHeader,
        DeviceName,
        DeviceWiFi,
        DeviceWiFiPassword,
        DeviceReset,
        DeviceTriggers,
        DeviceTriggerConfig,
        DeviceTriggerConfigTime,
        DeviceTriggerConfigAction,
        DeviceAutoSchedule,
        DeviceSeasonConfig,
        DeviceColors,
        DeviceToMusic,
        SortDevices,
        CurrentPlaylist,
        ColorPickerComponent,
        ColorSliderComponent,
        ColorPaletteComponent
    ],
    entryComponents:[],
    imports:[BrowserModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        IonicModule.forRoot({}),
        HttpClientModule,
        AppRouting,
        FormsModule],
    providers:[
        StatusBar,
        HTTP,
        SplashScreen,
        Insomnia,
        BluetoothLE,
        HomeSession,
        WebSocketService,
        CommandService,
        PubNubAngular,
        AuthGuard,
        {provide:RouteReuseStrategy, useClass:IonicRouteStrategy}
    ],
    bootstrap:[AppComponent],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
